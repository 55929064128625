import React from 'react';
import { Link, Redirect, Route, Router, Switch } from 'react-router-dom';
import axios from 'axios';
import { Button, Table, Container, Popup, Transition, Dimmer, Dropdown, Header, Icon, Loader, Menu, Modal, ModalContent, Image } from 'semantic-ui-react';
import LanguageOptions from '../src/components/LanguageOptions';
import AnnouncementsModal from './pages/announcements/AnnouncementsModal';
import LoginPage from './pages/user/LoginPage';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-toastify/dist/ReactToastify.css';
import DeviceListPage from './pages/device/DeviceListPage';
import NotFoundPage from './components/NotFoundPage';
import Library from './pages/app/Library';
import AppDetails from './pages/app/AppDetailsModal';
import DepAppList from './pages/deployment/DepAppList';
import { connect } from 'react-redux';
import { endFullLoad, updateDeviceList, updateDeviceListFilter, setStompClient, setSystemParameters} from './actions';
import './styles.css';
import './stylesv2.css';
import i18n from './translate/i18n';
import ProfilePage from './pages/user/ProfilePage';
import { withTranslation } from 'react-i18next';
import ReportingPage from './pages/usage/ReportingPage';
import { toast, ToastContainer } from 'react-toastify';
import 'rc-tree/assets/index.css';
import OptionsPage from './pages/options/OptionsPage';
import Bowser from 'bowser';
import { Base64 } from 'js-base64';
import { createBrowserHistory } from 'history';
import DocumentationPage from './pages/documentation/DocumentationPage';
import 'leaflet/dist/leaflet.css';
import { deleteOldCaches } from './utils/CacheStorageHandler';
import { logoutProcess, getPrivileges, setSessionValuesFromToken } from './utils/Methods';
import { stringify } from 'querystring';
import ApiDocumentationPage from './pages/documentation/components/ApiDocumentationPage';
import MultiManagePage from './pages/manage/MultiManagePage';
import ManualTestSessions from './pages/session/ManualTestSessions';
import AutomationTestSession from './pages/session/AutomationTestSession';
import NumberOfUsers from './pages/usage/tabs/NumberOfUsers/NumberOfUsers';
import TopUsers from './pages/usage/tabs/TopUsers/TopUsers';
import Reservations from './pages/usage/tabs/Reservations/Reservations';
import UserReservations from './pages/usage/tabs/UserReservations/UserReservations';
import Devices from './pages/usage/tabs/Devices/Devices';
import DeviceUsages from './pages/usage/tabs/DeviceUsage/DeviceUsages';
import RawUsageData from './pages/usage/tabs/RawUsageData/RawUsageData';
import InstantUsage from './pages/usage/InstantUsage';
import ApiUsage from './pages/usage/ApiUsage';
import Alarms from './pages/usage/Alarms';
import EmbeddedScreen from './pages/manage/EmbeddedScreen';
import {
    getLiveAndUnreadAnnouncements,
    markAnnouncementAsRead,
    refreshAccessToken,
    getSystemParameters,
    manageRefreshRequest
} from './api/apiCalls'
import { PrivilegeConstants } from './utils/PrivilegeConstants';
import { showError, showSuccess } from './utils/ToastHelpers';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { proxy } from '../package.json'
import {
    HTTP_STATUS,
    POPUP_POSITIONS,
    DEBOUNCE_WAIT
} from './utils/Constants';
import ChangeActiveGroupModal from './components/ChangeActiveGroupModal';
import {
    ALARMS_HEADER, API_DOCUMENTATION_HEADER, appMessages, AUTOMATION_TEST_SESSION_HEADER, 
    DEPLOYMENT_HEADER, DEVICES_HEADER, DEVICE_LIST_HEADER, LIBRARY_HEADER, LOADING, MANUAL_TEST_SESSION_HEADER,
    NOT_FETCH_SYSTEM_PARAMETERS_ERROR_MESSAGE, OK_BUTTON, reportingMessages, REPORTS_HEADER, RESERVATIONS_LABEL,
    TEST_SESSIONS_HEADER, announcementMessages
} from './utils/UIMessages';
import {
    ARROW_LEFT_ICON, ARROW_RIGHT_ICON, BARS_ICON, BELL_ICON, CHECKMARK_ICON, CHECK_ICON, INFO_ICON, QUESTION_CIRCLE_OUTLINE_ICON,
    SETTING_ICON, SIGN_OUT_ICON, USER_ICON, USER_OUTLINE_ICON
} from './utils/UiIcons';
import VersionPromoterModal from './components/VersionPromoter/VersionPromoterModal';
import vfLogo from './assets/images/visium-farm-logo.png';
import ChangeActiveGroupDropdown from './components/ChangeActiveGroupDropdown';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';


const BELL_TRANSITION_DURATION = 1000;
const NAVBAR_SMALL_SCREEN_WIDTH = 768;
const NAVBAR_ITEM_CLASS = 'navbar-menu-item';
const DEVICE_LIST_URL = '/device-list';

const history = createBrowserHistory();
//  axios.defaults.baseURL = 'http://10.254.186.20:8080/'

axios.interceptors.request.use(
    async config => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers.common['Authorization'] = 'Bearer ' + accessToken;
        }
        return config;
    },
    error => Promise.reject(error)
);

export const setProjecHeader = projectId => {
    axios.defaults.headers.common['X-Project-Id'] = projectId;
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        if (
            error?.response?.status === HTTP_STATUS.UNAUTHORIZED &&
            history.location.pathname !== '/login'
        ) {
            // refresh token
            const refreshToken = localStorage.getItem('refreshToken');
            if (
                originalRequest.url !== '/api/v1/token/refresh' &&
                !originalRequest.url.startsWith('/manage/api') &&
                !originalRequest._retry &&
                refreshToken &&
                originalRequest.url !== '/api/logout'
            ) {
                if (isRefreshing) {
                    // if the refresh request in proccess, then push the failed request to process queue
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject });
                    })
                        .then(token => {
                            originalRequest.headers['Authorization'] =
                                'Bearer ' + token;
                            return axios(originalRequest);
                        })
                        .catch(err => Promise.reject(err));
                }

                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise(function (resolve, reject) {
                    //axios.post('/api/token/refresh', { refreshToken })
                    refreshAccessToken(refreshToken).then(({ data }) => {
                        const { accessToken, refreshToken, user } = data;
                        setSessionValuesFromToken(accessToken, refreshToken, user);
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + data.accessToken;
                        resolve(axios(originalRequest));
                        processQueue(null, data.accessToken);
                    })
                        .catch(err => {
                            reject(err);
                            processQueue(err, null);
                            logout();
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                });
            } 
        }
        return Promise.reject(error);
    }
);

//TODO: Merge with the interceptor above
//      Add refresh / retry check
//      Implement failed queue
axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === HTTP_STATUS.UNAUTHORIZED && error.config.url.startsWith('/manage/api')) {
        const refresh_token = localStorage.getItem('manageAccessToken');
        if (refresh_token) {
          try {
            const response = await manageRefreshRequest();
            localStorage.setItem('manageAccessToken', response.data.accessToken);
            localStorage.setItem('manageRefreshToken', response.data.refreshToken);
            return axios.request(error.response.config);
          } catch (e) {
            showError(`${appMessages().INFORMATION_MODAL_HEADER}: ${e}`);
          }
        }
      }
      return Promise.reject(error);
    }
  );

const logout = () => {
    const data = { refreshToken: localStorage.getItem('refreshToken') };
    if (data.refreshToken) {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: stringify(data),
            url: '/api/logout'
        };
        axios(options).then(() => {
            toast.dismiss();
            deleteOldCaches('images-1');
        });
    }
    
    sessionStorage.setItem('lang', localStorage.getItem('lang'));
    sessionStorage.removeItem('deviceSearchUrl');
    logoutProcess();
};

const toggleNavbarVisibility = () => {
    // if check normal boyuttayken navbar elemenlarının kaybolmasını önlemek için
    if (window.innerWidth <= NAVBAR_SMALL_SCREEN_WIDTH) {
        const itemList = document.getElementsByClassName(NAVBAR_ITEM_CLASS);
        itemList.forEach(item => {
            if (item.style.display === 'none') {
                item.style.display = 'flex';
            } else {
                item.style.display = 'none';
            }
        });
    }
}

const handleNavbarOnResize = () => {
    const itemList = document.getElementsByClassName(NAVBAR_ITEM_CLASS);
    if (window.innerWidth > NAVBAR_SMALL_SCREEN_WIDTH) {
        Array.from(itemList).forEach(item => item.style.display = 'flex');
    } else {
        Array.from(itemList).forEach(item => item.style.display = 'none');
    }
}


//---------------------------------------------------------------------------------
// Under a specific path, related name on the header will be glorified
const glorify = {
    color: 'white',
    borderBottom: '3px solid  white',
    marginTop: '3px'
}

//When there is a new announcement, the bell in the top menu bar will be glorified as below
const glorifyNewAnnouncement = {
    paddingTop: '13px',
    paddingLeft: '10px',
    color: 'red',
    marginTop: '1px',
    borderRadius: '8px',
    height: '100%',
    width: '55px',
    background: 'grey'
}

//---------------------------------------------------------------------------------

class App extends React.Component {

    constructor(props) {
        super(props);

        if (localStorage.getItem('lang')) {
            i18n.changeLanguage(localStorage.getItem('lang'));
        } else if (sessionStorage.getItem('lang')) {
            i18n.changeLanguage(sessionStorage.getItem('lang'));
        } else {
            i18n.changeLanguage('eng');
        }

        this.state = {
            modalVisibility: false,

            //Announcement Variables
            readAnnouncementsIdsArr: [],
            openAnnouncementsModal: false,

            //announcements to be displayed to user on every page
            unreadAnnouncements: [],
            unreadAnnouncementIndex: 0,

            //show these announcements FOR ALL users
            publishingAnnouncementsArray: [],

            userDetails: {},
            isPrivilegeLoaded: false,
            privileges: [],
            pathNameForGlorify: localStorage.getItem('pathSaved') || '',
            activeGroupModalOpened: false,

            //New Announcement Bell Transition
            animation: 'tada',
            duration: BELL_TRANSITION_DURATION,
            visible: true,
            systemParameters:[],
            showTimeoutModal: localStorage.getItem('timeoutModalState'),
            visiumManageInformation: {},

            //Promoting New Features
            openVersionPromoterModal: false,
            
            lastKeepAliveMessageTime: Math.floor(Date.now()/1000),
            stompClient: undefined,
            stompSessionChannelId: undefined,
        }
    }

    getUnreadAnnouncements = () => {
        if (localStorage.getItem('userDetails')) {
            getLiveAndUnreadAnnouncements().then(res => {
                this.setState({ unreadAnnouncements: res?.data }, () => {
                    if (this.state.unreadAnnouncementIndex >= this.state.unreadAnnouncements.length) {
                        this.setState({ unreadAnnouncementIndex: 0})
                    }
                })
            }).catch(() => {
                showError(announcementMessages().NOT_FETCH_NON_READ_ANNOUNCEMENTS);
            })
        }
    }

    //When a new annoucnement is read by the current user, the announcement will be updated: announcement.readByUsers += currentUser
    onClickReadAnnouncement = async announcement => {
        markAnnouncementAsRead(announcement.id)
            .then(() => {
                this.getUnreadAnnouncements();
                showSuccess(announcementMessages().READ_SUCCESSFUL)
            })
            .catch(() => showError(announcementMessages().READ_ERROR))
    }

    closeShowAnnouncementsModal = () => this.setState({ openAnnouncementsModal: false });
    
    sendKeepAliveMessage = () => {
        if (this.state.stompClient?.connected && this.state.lastKeepAliveMessageTime + 10 < Math.floor(Date.now() / 1000)) {
            this.state.stompClient.send("/app/session/keepalive", {}, this.state.stompSessionChannelId);
            this.setState({ lastKeepAliveMessageTime: Math.floor(Date.now() / 1000) });
        }
    }

    handleMouseMove = _.debounce(this.sendKeepAliveMessage, DEBOUNCE_WAIT.SHORT);

    saveGlorifyMenuItems = pathNameForGlorification => {
        this.setState({ pathNameForGlorify: pathNameForGlorification });
        window.localStorage.setItem(
            'pathSaved',
            pathNameForGlorification
        );
    }


    /**
     * This function generate the device list url with history params
     *
     * @returns {string}
     */
    generateDeviceListUrl = () => {
        const deviceSearchUrl = sessionStorage.getItem('deviceSearchUrl');
        return DEVICE_LIST_URL + (deviceSearchUrl ?? '');
    }

    informationModal = () => {
        return (
            <Modal
                open={this.state.modalVisibility}
                basic
                style={{ textAlign: 'center' }}
                size={'tiny'}
            >
                <Header icon={INFO_ICON} content={appMessages().INFORMATION_MODAL_HEADER} />
                <Modal.Content>
                    <p>{appMessages().INFORMATION_MODAL_CONTENT}.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='green'
                        inverted
                        onClick={() => {
                            this.setState({ modalVisibility: false });
                            sessionStorage.setItem('isChrome', 'true');
                        }}
                    >
                        <Icon name={CHECKMARK_ICON} /> {OK_BUTTON()}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    componentWillUnmount() {
        window.removeEventListener('resize', handleNavbarOnResize);
    }

    componentDidUpdate() {
        if (this.state.pathNameForGlorify !== localStorage.getItem('pathSaved')) {
            if (!this.isUserLoggedIn() && !this.isEmbedView()) {
                window.location = '/login';
                this.saveGlorifyMenuItems('/login');
            } else {
                this.saveGlorifyMenuItems(localStorage.getItem('pathSaved'))
            }
        }
    }

    componentDidMount() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const isChrome = browser
            .getBrowserName()
            .toLowerCase()
            .includes('chrome');
        if (!isChrome && !sessionStorage.getItem('isChrome')) {
            this.setState({ modalVisibility: true });
        }
        if (localStorage.getItem('userDetails')) {
            const userDetails = JSON.parse(
                Base64.decode(localStorage.getItem('userDetails'))
            );
            this.setState({ userDetails });
        }
        if (localStorage.getItem('privileges')) {
            const privileges = getPrivileges();
            this.setState({ privileges }, () => this.setState({ isPrivilegeLoaded: true }));
        }
        this.setState({openVersionPromoterModal: JSON.parse(localStorage.getItem('showVersionPromoterModal')) === true ? true : false})
        
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('resize', handleNavbarOnResize);
        this.getUnreadAnnouncements();
        if (localStorage.getItem('userDetails') && localStorage.getItem('privileges')) {
            this.websocketConnection();
            this.getSystemParameters();
        }

        // for page first renderer
        const path = window.location.pathname;
        if(path !== localStorage.getItem('pathSaved')){
            localStorage.setItem('pathSaved',path);
        }

        history.listen(path => {
            //trigger new announcements in each page change.
            this.getUnreadAnnouncements();
            //when changed page address, clear all toast
            toast.dismiss();
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });

            if(path?.pathname !== localStorage.getItem('pathSaved')){
                this.saveGlorifyMenuItems(path?.pathname);
            }

        });
        this.setState({activeGroupModalOpened: localStorage.getItem('showActiveGroupModal') === 'true'})
    }

    getSystemParameters = async () => {
        try {
            const res = await getSystemParameters();
            this.setState({systemParameters:res.data})
            this.props.setSystemParameters(res.data);
        } catch (err) {
            showError(`${NOT_FETCH_SYSTEM_PARAMETERS_ERROR_MESSAGE()} ${err?.response?.data?.message ?? err.toString()}`)
        }
    }

    renderEmbeddedScreens() {
        return (
            <>
                {(window.location.href.indexOf('/embed-view/') > -1) &&
                    <Route exact path='/embed-view/:token' component={EmbeddedScreen} />
                }
                {(window.location.href.indexOf('/embed-manage/') > -1) &&
                    <Route exact path='/embed-manage/:token' component={MultiManagePage} />
                }
                {(window.location.href.indexOf('/embed-inspector/') > -1) &&
                    <Route exact path='/embed-inspector/:token' component={MultiManagePage} />
                }
            </>
        );
    }

    websocketConnection = () => {
        this.setState({stompSessionChannelId: uuidv4()}, () => {
            let url;
            const { hostname, port, protocol, host } = window.location;
            if (host.includes('127.0.0.1:') || host.includes('localhost:') || process.env.NODE_ENV === 'development') {
                url = `${proxy}/ws?token=${localStorage.getItem('accessToken')}`;
            } else {
                url = `${protocol}//${hostname}:${port}/ws?token=${localStorage.getItem('accessToken')}`;
            }
            const socket = new SockJS(url);
            const client = Stomp.over(socket);
    
            client.debug = f => f;
            client.connect({"SessionChannelId": this.state.stompSessionChannelId}, () => {
                this.setState({stompClient: client});
    
                this.props.setStompClient(client);
    
                client.subscribe('/topic/device-status', message => {
                    const device = JSON.parse(message?.body);
                    device.visible = true;
                    if( !this.state.privileges?.includes(PrivilegeConstants.SUPREME_DEVICE_MANAGEMENT) && device?.users !== null && device?.manual === true){
                        const visibilityOfBusyDevice =  this.state.systemParameters?.find(systemParameter => 
                        systemParameter?.parameterKey === 'visibilityOfBusyDevice');
                        if(visibilityOfBusyDevice?.parameterValue === 'no'){
                            device.visible = false;
                        }else if(visibilityOfBusyDevice?.parameterValue === 'group only'){
                            if(device?.users?.every(user=> user?.activeGroupId != localStorage.getItem('activeGroup'))){
                                device.visible = false;
                            }
                        }
                    }
                    this.props.updateDeviceList(device);
                    this.props.updateDeviceListFilter(device);
                });
    
                client.subscribe(`/queue/notification/${this.state.stompSessionChannelId}`, message => {
                    const notification = JSON.parse(message?.body);
                    if (notification.type === "SESSION_TIMEOUT") {
                        logout();
                        localStorage.setItem('timeoutModalState', true);
                    }
                });
            });
        });       
    }

    isUserLoggedIn = () => localStorage.getItem('userDetails') && localStorage.getItem('privileges');

    isEmbedView = () => window.location.href.indexOf('/embed-') > -1;

    renderLogin() {
        return (
            <>
                <Route exact path='/' component={() => <Redirect to={'/login'} />} />
                <Route exact path='/login' component={LoginPage} />
                {this.informationModal()}
                <ToastContainer />
                {this.renderTimeoutModal()}
            </>
        );
    }

    renderLoginOrEmbeddedScreen() {
        return (
            <Router history={history}>
                <React.Fragment>
                    <Dimmer
                        active={this.props.loading}
                        blurring={'true'}
                        page={true}
                        style={{ zIndex: 99999 }}
                    >
                        <Loader
                            active={this.props.loading}
                            inline={'centered'}
                            indeterminate
                            id={'loaderText'}
                        >
                            {' '}
                            {LOADING()}
                            {this.props.loadingContent}{' '}
                        </Loader>
                    </Dimmer>
                    <Container className='page-container-full' fluid>
                        <div>
                            {
                                (window.location.href.indexOf('/embed-') > -1) ?
                                    this.renderEmbeddedScreens() : this.renderLogin()
                            }
                        </div>
                    </Container>
                </React.Fragment>
            </Router>
        );
    }

    renderLogoMenuItem() {
        return (
            <>
                <Menu.Item header style={{ 'justifyContent': 'space-between' }}>
                    <Image
                        className='vf-logo-on-nav-bar'
                        src={vfLogo}
                        as={Link}
                        to={this.generateDeviceListUrl}
                        size='small'
                    />
                    <Icon id='navbar-menu-toggle-icon' name={BARS_ICON} onClick={() => toggleNavbarVisibility()} />
                </Menu.Item>
            </>
        )
    }

    renderDeviceListMenuItem() {
        return (
            <>
                {this.state.privileges.includes(PrivilegeConstants.DEVICE_LIST) && (
                    <Menu.Item
                        as={Link}
                        to={this.generateDeviceListUrl}
                        position='right'
                        style={this.state.pathNameForGlorify === '/device-list' ? glorify
                            : { borderRight: 'solid 0.50px #2e2e5b', borderLeft: 'solid 0.5px #2e2e5b' }}
                        className='navbar-menu-item'>
                        {/* <Icon name='list layout' /> */}
                        {DEVICE_LIST_HEADER()}
                    </Menu.Item>
                )}
            </>
        )
    }

    renderTestSessionsMenuItem() {
        return (
            <>
                {(([
                    PrivilegeConstants.MANUAL_TEST_SESSIONS.CREATE,
                    PrivilegeConstants.MANUAL_TEST_SESSIONS.VIEW_ANY,
                    PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT
                ].some(privilege => this.state.privileges.includes(privilege))) ||
                    ([
                        PrivilegeConstants.AUTOMATION_TEST_SESSIONS.CREATE,
                        PrivilegeConstants.AUTOMATION_TEST_SESSIONS.VIEW_ANY,
                        PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT
                    ].some(privilege => this.state.privileges.includes(privilege)))) && (
                        <Dropdown
                            style={(this.state.pathNameForGlorify === '/manual-test-sessions' || this.state.pathNameForGlorify === '/automation-test-sessions')
                                ? glorify : { borderLeft: 'solid 0.5px #2e2e5b' }}
                            text={TEST_SESSIONS_HEADER()}
                            item direction='left' className='navbar-menu-item'>
                            <Dropdown.Menu>
                                {
                                    ([
                                        PrivilegeConstants.MANUAL_TEST_SESSIONS.CREATE,
                                        PrivilegeConstants.MANUAL_TEST_SESSIONS.VIEW_ANY,
                                        PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT
                                    ].some(privilege => this.state.privileges.includes(privilege))) &&
                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/manual-test-sessions'} as={Link}
                                        to='/manual-test-sessions'>
                                        {MANUAL_TEST_SESSION_HEADER()}
                                    </Dropdown.Item>
                                }
                                {
                                    ([
                                        PrivilegeConstants.AUTOMATION_TEST_SESSIONS.CREATE,
                                        PrivilegeConstants.AUTOMATION_TEST_SESSIONS.VIEW_ANY,
                                        PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT
                                    ].some(privilege => this.state.privileges.includes(privilege))) &&
                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/automation-test-sessions'} as={Link}
                                        to='/automation-test-sessions'>
                                        {AUTOMATION_TEST_SESSION_HEADER()}
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
            </>
        )
    }

    renderLibraryMenuItem() {
        return (
            <>
                {([
                    PrivilegeConstants.FILE_MANAGEMENT.LIST,
                    PrivilegeConstants.APPLICATION_MANAGEMENT.LIBRARY_ACCESS
                ].some(privilege => this.state.privileges.includes(privilege))) && (
                        <Menu.Item style={this.state.pathNameForGlorify === '/apps' ? glorify : null} as={Link} to='/apps'
                            className='navbar-menu-item'>
                            {/* <Icon name='box' /> */}
                            {LIBRARY_HEADER()}
                        </Menu.Item>
                    )}
            </>
        )
    }

    renderDeploymentMenuItem() {
        return (
            <>
                {([
                    PrivilegeConstants.APPLICATION_MANAGEMENT.INSTALL,
                    PrivilegeConstants.FILE_MANAGEMENT.DEPLOY
                ].some(privilege => this.state.privileges.includes(privilege))) && (
                        <Menu.Item as={Link} style={this.state.pathNameForGlorify === '/deployment-apps' ? glorify : null}
                            to='/deployment-apps' className='navbar-menu-item' >
                            {/* <Icon name='cloud upload' /> */}
                            {DEPLOYMENT_HEADER()}
                        </Menu.Item>
                    )}
            </>
        )
    }

    renderReportsMenuItem() {
        return (
            <>
                {([
                    PrivilegeConstants.API_USAGE_REPORT,
                    PrivilegeConstants.RAW_DEVICE_USAGE_REPORT,
                    PrivilegeConstants.INSTANT_USAGE_REPORT,
                    PrivilegeConstants.DEVICE_USAGE_REPORT,
                    PrivilegeConstants.ALARMS
                ].some(privilege => this.state.privileges.includes(privilege))) && (
                    <Dropdown style={this.state.pathNameForGlorify.startsWith('/reports') ? glorify : null} text={REPORTS_HEADER()}
                              item direction='left' className='navbar-menu-item'>
                        <Dropdown.Menu>
                            {this.state.privileges.includes(PrivilegeConstants.DEVICE_USAGE_REPORT) && (<>
                                <Dropdown.Item selected={this.state.pathNameForGlorify === '/reports/number-of-users'} as={Link} to='/reports/number-of-users'>
                                    {reportingMessages().NUMBER_OF_USERS_MENU_ITEM}
                                </Dropdown.Item>

                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/reports/top-users'} as={Link} to='/reports/top-users'>
                                        {reportingMessages().TOP_USERS_MENU_ITEM}
                                    </Dropdown.Item>

                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/reports/reservations'} as={Link} to='/reports/reservations'>
                                        {RESERVATIONS_LABEL()}
                                    </Dropdown.Item>

                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/reports/user-reservations'}
                                        as={Link} to='/reports/user-reservations'>
                                        {reportingMessages().USER_RESERVATIONS_MENU_ITEM}
                                    </Dropdown.Item>

                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/reports/devices'} as={Link} to='/reports/devices'>
                                        {DEVICES_HEADER()}
                                    </Dropdown.Item>

                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/reports/device-usages'} as={Link} to='/reports/device-usages'>
                                        {reportingMessages().DEVICE_USAGE_MENU_ITEM}
                                    </Dropdown.Item>
                                </>)}

                                {(this.state.privileges.includes(PrivilegeConstants.RAW_DEVICE_USAGE_REPORT)) && (
                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/reports/raw-usage-data'} as={Link} to='/reports/raw-usage-data'>
                                        {reportingMessages().RAW_USAGE_DATA_MENU_ITEM}
                                    </Dropdown.Item>
                                )}

                                {this.state.privileges.includes(PrivilegeConstants.INSTANT_USAGE_REPORT) && (
                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/reports/instant-usage'} as={Link} to='/reports/instant-usage'>
                                        {reportingMessages().INSTANT_USAGE_MENU_ITEM}
                                    </Dropdown.Item>
                                )}

                                {this.state.privileges.includes(PrivilegeConstants.API_USAGE_REPORT) && (
                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/reports/api-usage'} as={Link} to='/reports/api-usage'>
                                        {reportingMessages().API_USAGE_MENU_ITEM}
                                    </Dropdown.Item>
                                )}

                                {this.state.privileges.includes(PrivilegeConstants.ALARMS) && (
                                    <Dropdown.Item selected={this.state.pathNameForGlorify === '/reports/alarms'} as={Link} to='/reports/alarms'>
                                        {ALARMS_HEADER()}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
            </>
        )
    }

    renderHelpCenterMenuItem() {

        const helpSubBranches = ['/documentation','/api-documentation'];

        return (
            <>
                <Dropdown
                    style={helpSubBranches.indexOf(this.state.pathNameForGlorify) > -1 ? glorify : null}
                    item direction='left' className='navbar-menu-item'
                    text={<><Icon id='set-icon-visibility-responsive' name={QUESTION_CIRCLE_OUTLINE_ICON} />
                        <span className='set-text-visibility-responsive'>{appMessages().HELP_LABEL}</span></>}>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => this.setState({ openVersionPromoterModal: true })} >
                        {appMessages().WHAT_IS_NEW_MENU_ITEM} 
                        </Dropdown.Item>
                        <Dropdown.Item selected={this.state.pathNameForGlorify === '/documentation'} as={Link} to='/documentation'>
                            {appMessages().HELP_CENTER_MENU_ITEM}
                        </Dropdown.Item>
                        <Dropdown.Item as={Link}
                            to='//support.visiumlabs.com/' target='_blank'>
                            {appMessages().CONTACT_US_MENU_ITEM}
                        </Dropdown.Item>

                        <Dropdown.Item selected={this.state.pathNameForGlorify === '/api-documentation'} as={Link} to='/api-documentation'>
                            {API_DOCUMENTATION_HEADER()}
                        </Dropdown.Item>

                        <Dropdown.Item as={Link}
                            to='/tools/VisiumFarmRDC_Win.zip' target='_blank'>
                            {appMessages().REMOTE_DEBUGGING_CLIENT_WIN}
                        </Dropdown.Item>

                        <Dropdown.Item as={Link}
                            to='/tools/VisiumFarmRDC_Mac.zip' target='_blank'>
                            {appMessages().REMOTE_DEBUGGING_CLIENT_MAC}
                        </Dropdown.Item>

                        <Dropdown.Item as={Link}
                            to='/tools/VisiumFarmRDC.jar' target='_blank'>
                            {`${this.props.t('Remote Debugging Client (Jar)')}`}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    renderSettingsMenuItem() {
        return (
            <>
                <Menu.Item style={this.state.pathNameForGlorify === '/settings' ? glorify : null} as={Link} to='/settings' className='navbar-menu-item' >
                    <div style={{ marginTop: '2px' }}><Icon name={SETTING_ICON} id='set-icon-visibility-responsive' />
                        <span className='set-text-visibility-responsive'>{appMessages().SETTINGS_MENU_ITEM}</span>
                    </div>
                </Menu.Item>
            </>
        )
    }
    renderChangeActiveGroupDropdownMenuItem() {
        return (
                <Menu.Item  className="menuItem">
                    <ChangeActiveGroupDropdown />
                </Menu.Item>
        )
    }

    renderAnnouncementsMenuItem() {
        return (
            <>
                {(<Transition
                    animation={this.state.animation}
                    duration={this.state.duration}
                    visible={this.state.visible}
                >
                    <Menu.Item className='navbar-menu-item'
                        onClick={() => { this.setState({ openAnnouncementsModal: true }) }}>
                        <div id='non-responsive-announcements' style={this.state.unreadAnnouncements?.length > 0 ? glorifyNewAnnouncement : null}>
                            <Icon name={BELL_ICON} />
                            {
                                this.state.unreadAnnouncements?.length > 0 ? <b style={{ color: 'white' }}>
                                    {this.state.unreadAnnouncements?.length}</b> : ''
                            }
                        </div>
                        <p id='responsive-announcements'>
                            <span>{appMessages().ANNOUNCEMENTS_MENU_ITEM} </span>
                            {
                                this.state.unreadAnnouncements?.length > 0 ? <b id='responsive-instant-new-announcement'>
                                    {this.state.unreadAnnouncements?.length}</b> : ''
                            }
                        </p>
                    </Menu.Item>
                </Transition>
                )}
            </>
        )
    }

    renderUserProfileMenuItem() {
        return (
            <>
                <Dropdown style={this.state.pathNameForGlorify === '/me' ? glorify : null} direction='left' item
                    className='navbar-menu-item'
                    text={<><Icon name={USER_OUTLINE_ICON} id='set-icon-visibility-responsive' />
                        <span className='set-text-visibility-responsive'>{appMessages().PROFILE_MENU_ITEM}</span></>} >
                    <Dropdown.Menu  style ={{width: '200px'}}>
                        <LanguageOptions  showFlagOf={localStorage.getItem('lang') === 'tr' ? 'tr' : 'en'} />
                        <Dropdown.Divider />
                        <Dropdown.Item selected={this.state.pathNameForGlorify === '/me'} as={Link} to='/me'>
                            <Icon name={USER_ICON} />
                            {appMessages().PROFILE_MENU_ITEM}
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                            onClick={() => this.setState({ activeGroupModalOpened: true })} >
                            <Icon name='users' />
                            {CHANGE_ACTIVE_GROUP()}
                        </Dropdown.Item> */}

                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => {
                            logout();
                            toggleNavbarVisibility();
                            window.localStorage.setItem(
                                'pathSaved',
                                ''
                            );
                            this.setState({ isPrivilegeLoaded: false });
                        }}> <Icon name={SIGN_OUT_ICON} />{appMessages().SIGN_OUT_MENU_ITEM}
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    renderMenu() {
        const notUseMenuLinks = ['/embed-','/video'];
        return (
            <>
                {
                    (notUseMenuLinks.every( link => window.location.href.indexOf(link) === -1)) &&
                    <Menu
                        stackable
                        fixed='top'
                        inverted
                        className='nav-bar-new'
                    >
                        {this.renderLogoMenuItem()}
                        <Menu.Menu position='right'>
                            {this.renderDeviceListMenuItem()}
                            {this.renderTestSessionsMenuItem()}
                            {this.renderLibraryMenuItem()}
                            {this.renderDeploymentMenuItem()}
                            {this.renderReportsMenuItem()}
                            {this.renderAnnouncementsMenuItem()}
                            {this.renderHelpCenterMenuItem()}
                            {this.renderSettingsMenuItem()}
                            {this.renderChangeActiveGroupDropdownMenuItem()}
                            {this.renderUserProfileMenuItem()}
                        </Menu.Menu>
                    </Menu>
                }
            </>
        )
    }


    renderVersionPromoterModal() {
        return (
            <>
                <VersionPromoterModal
                    openProp={this.state.openVersionPromoterModal}
                    setOpenProp={(openVersionPromoterModal) => {
                        this.setState({ openVersionPromoterModal })
                    }}
                />
            </>
        )
    }

    renderAnnouncements() {
        return (
            <>
                {/* Instant new announcements panel------------------------------------------------ */}
                {this.state.unreadAnnouncements?.length > 0 && !this.isEmbedView() &&
                    <>{<div className='main-container-new-announcements'>
                        <Table selectable id='main-container-new-announcements-table'>
                            <Table.Body >
                                {<Table.Row>
                                    <Table.Cell>{this.state.unreadAnnouncements?.length > 1 && <>
                                        <Icon className='new-announcement-change-button-arrows' name={ARROW_LEFT_ICON}
                                            onClick={() => {
                                                //Check array index and set properly
                                                if (this.state.unreadAnnouncementIndex === 0) {
                                                    //back to end of array
                                                    this.setState({ unreadAnnouncementIndex: this.state.unreadAnnouncements.length - 1 })
                                                } else { this.setState({ unreadAnnouncementIndex: this.state.unreadAnnouncementIndex - 1 }) }
                                            }} />
                                        <Icon className='new-announcement-change-button-arrows' name={ARROW_RIGHT_ICON}
                                            onClick={() => {
                                                //Check array index and set properly
                                                if (this.state.unreadAnnouncementIndex >= this.state.unreadAnnouncements.length - 1) {
                                                    this.setState({ unreadAnnouncementIndex: 0 })
                                                    //back to beginning of array
                                                } else { this.setState({ unreadAnnouncementIndex: this.state.unreadAnnouncementIndex + 1 }) }
                                            }} />
                                    </>}
                                        <Popup position={POPUP_POSITIONS.BOTTOM_RIGHT} content={announcementMessages().MARK_READ_POPUP}
                                            trigger={<Icon id='new-announcement-change-button-checkmark' name={CHECK_ICON}
                                                onClick={() => this.onClickReadAnnouncement(this.state.unreadAnnouncements[this.state.unreadAnnouncementIndex])} />}
                                        />
                                        <b> <b id='new-announcement-warning-msg'>{appMessages().NEW_ANNOUNCEMENT_LABEL} {this.state.unreadAnnouncementIndex + 1} |
                                        </b> {this.state.unreadAnnouncements[this.state.unreadAnnouncementIndex]?.name}: </b>
                                        {this.state.unreadAnnouncements[this.state.unreadAnnouncementIndex]?.description}
                                    </Table.Cell>
                                </Table.Row>}
                            </Table.Body>
                        </Table>
                    </div>}</>}

                <AnnouncementsModal
                    openShowAnnouncemetsModalProp={this.state.openAnnouncementsModal}
                    closeShowAnnouncementModalFuncProp={this.closeShowAnnouncementsModal}
                    publishingAnnouncementsArrayProp={this.state.publishingAnnouncementsArray}
                    onClickReadAnnouncementProp={this.onClickReadAnnouncement}
                    getNewAnnouncementsFuncProp={this.getUnreadAnnouncements}
                    localeProp={this.props.i18n.language}
                />

            </>
        )
    }

    renderTimeoutModal = () => {
        return(
        <Modal size='mini'
            open={this.state.showTimeoutModal === 'true'}
            onClose={()=> {
                this.setState({showTimeoutModal: false})
                localStorage.setItem('timeoutModalState', false);
            }}
            closeOnEscape={true}
            closeIcon
            centered={true}>
            <ModalContent style={{textAlign: 'center'}}>
                {appMessages().SESSION_TIMEOUT}
            </ModalContent>
        </Modal>
        )        
        }

    render() {
        // #fordevelopment
        if (!this.isUserLoggedIn()) {
            return this.renderLoginOrEmbeddedScreen();
        } else {
            return (
                <Router history={history}>
                    <React.Fragment>
                        <Dimmer
                            active={this.props.loading}
                            blurring={'true'}
                            page={true}
                            style={{ zIndex: 99999 }}
                        >
                            <Loader
                                active={this.props.loading}
                                inline={'centered'}
                                indeterminate
                                id={'loaderText'}
                            >
                                {' '}
                                {LOADING()}
                                {this.props.loadingContent}{' '}
                            </Loader>
                        </Dimmer>

                        {this.renderMenu()}
                        {this.renderAnnouncements()}
                        {this.renderVersionPromoterModal()}

                        {/*RBA checks*/}
                        <Container className='page-container-full' fluid>
                            <Switch>
                                <Route
                                    path='/'
                                    exact
                                    component={() => (
                                        <Redirect to={DEVICE_LIST_URL} />
                                    )}
                                />
                                <Route
                                    path='/login'
                                    exact
                                    component={() => (
                                        <Redirect to={DEVICE_LIST_URL} />
                                    )}
                                />
                                {this.state.privileges.includes(PrivilegeConstants.DEVICE_LIST) && (
                                    <Route
                                        exact
                                        path={DEVICE_LIST_URL}
                                        component={DeviceListPage}
                                    />
                                )}
                                <Route exact path='/me' component={ProfilePage} />
                                <Route
                                    exact
                                    path='/device-manage'
                                    component={MultiManagePage}
                                />
                                {([
                                    PrivilegeConstants.FILE_MANAGEMENT.LIST,
                                    PrivilegeConstants.APPLICATION_MANAGEMENT.LIBRARY_ACCESS
                                ].some(privilege => this.state.privileges.includes(privilege))) && (
                                        <Route exact path='/apps' component={Library} />
                                    )}
                                <Route
                                    exact
                                    path='/apps/:appPackage'
                                    component={AppDetails}
                                />

                                {([
                                    PrivilegeConstants.APPLICATION_MANAGEMENT.INSTALL,
                                    PrivilegeConstants.FILE_MANAGEMENT.DEPLOY
                                ].some(privilege => this.state.privileges.includes(privilege))) && (
                                        <Route
                                            exact
                                            path='/deployment-apps'
                                            component={DepAppList}
                                        />
                                    )}
                                {([
                                    PrivilegeConstants.API_USAGE_REPORT,
                                    PrivilegeConstants.RAW_DEVICE_USAGE_REPORT,
                                    PrivilegeConstants.INSTANT_USAGE_REPORT,
                                    PrivilegeConstants.DEVICE_USAGE_REPORT
                                ].some(privilege => this.state.privileges.includes(privilege))) && (
                                        <Route exact path='/reports' component={ReportingPage} />

                                    )}
                                <Route
                                    exact
                                    path='/settings'
                                    component={OptionsPage}
                                />
                                {([
                                    PrivilegeConstants.MANUAL_TEST_SESSIONS.CREATE,
                                    PrivilegeConstants.MANUAL_TEST_SESSIONS.VIEW_ANY,
                                    PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT
                                ].some(privilege => this.state.privileges.includes(privilege))) && <Route
                                        exact
                                        path='/manual-test-sessions'
                                        component={ManualTestSessions}
                                    />}
                                {([
                                    PrivilegeConstants.AUTOMATION_TEST_SESSIONS.CREATE,
                                    PrivilegeConstants.AUTOMATION_TEST_SESSIONS.VIEW_ANY,
                                    PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT
                                ].some(privilege => this.state.privileges.includes(privilege))) && <Route
                                        exact
                                        path='/automation-test-sessions'
                                        component={AutomationTestSession}
                                    />}
                                <Route
                                    exact
                                    path='/documentation'
                                    component={DocumentationPage}
                                />
                                <Route
                                    exact
                                    path='/documentation/:articleId'
                                    component={DocumentationPage}
                                />
                                <Route
                                    exact
                                    path='/api-documentation'
                                    component={ApiDocumentationPage}
                                />
                                <Route exact path='/embed-view/:token' component={EmbeddedScreen} />
                                <Route exact path='/embed-manage/:token' component={MultiManagePage} />
                                <Route exact path='/embed-inspector/:token' component={MultiManagePage} />
                                <Route exact path='/reports/number-of-users' component={NumberOfUsers} />
                                <Route exact path='/reports/top-users' component={TopUsers} />
                                <Route exact path='/reports/reservations' component={Reservations} />
                                <Route exact path='/reports/user-reservations' component={UserReservations} />
                                <Route exact path='/reports/devices' component={Devices} />
                                <Route exact path='/reports/device-usages' component={DeviceUsages} />
                                <Route exact path='/reports/raw-usage-data' component={RawUsageData} />
                                <Route exact path='/reports/instant-usage' component={InstantUsage} />
                                <Route exact path='/reports/api-usage' component={ApiUsage} />
                                <Route exact path='/reports/alarms' component={Alarms} />
                                <Route exact path='/manual-test-sessions?refreshToken=:token' component={ManualTestSessions} />
                                {this.state.isPrivilegeLoaded && <Route component={NotFoundPage} />}
                            </Switch>
                        </Container>
                        <ToastContainer />
                        {(this.state.activeGroupModalOpened && !this.state.openVersionPromoterModal) ?
                            <ChangeActiveGroupModal
                                open={this.state.activeGroupModalOpened}
                                onClose={() => {
                                    this.setState({ activeGroupModalOpened: false })
                                    if (localStorage.getItem('showActiveGroupModal') && localStorage.getItem('showActiveGroupModal') === 'true') {
                                        localStorage.setItem('showActiveGroupModal', false)
                                    }
                                }}
                            />
                            : null}
                    </React.Fragment>
                </Router>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        loading: state.notification.loading,
        loadingContent: state.notification.loadingContent,
        systemParameters: state.systemParameters.systemParameters
    };
};

export default withTranslation()(
    connect(mapStateToProps, { endFullLoad, updateDeviceList, updateDeviceListFilter, setStompClient, setSystemParameters })(App)
);

// [ {
//     'name' : 'Application Installation',
//     'description' : 'Install application to devices',
//     'id' : 2
// }, {
//     'name' : 'ADB Command',
//     'description' : 'Run adb commands on Android devices',
//     'id' : 3
// }, {
//     'name' : 'Device List',
//     'description' : 'List all devices on the system',
//     'id' : 4
// }, {
//     'name' : 'Supreme Device Reservation',
//     'description' : 'Manage reservations even also belongs another user',
//     'id' : 5
// }, {
//     'name' : 'Remove Device',
//     'description' : 'Delete device from the system (you can delete device only offline status)',
//     'id' : 6
// }, {
//     'name' : 'Device Information',
//     'description' : 'Access device information on the system.',
//     'id' : 7
// }, {
//     'name' : 'Appium Logs',
//     'description' : 'Access Appium logs',
//     'id' : 8
// }, {
//     'name' : 'Device Logs',
//     'description' : 'Access device logs',
//     'id' : 9
// }, {
//     'name' : 'Device Usage Report',
//     'description' : 'Access usage report',
//     'id' : 10
// }, {
//     'name' : 'Edit Device Information',
//     'description' : 'Edit device information',
//     'id' : 11
// }, {
//     'name' : 'Stop Automation',
//     'description' : 'Remove grid session of device',
//     'id' : 12
// }, {
//     'name' : 'User Report',
//     'description' : 'Access device's user report',
//     'id' : 13
// }, {
//     'name' : 'Reservation System',
//     'description' : 'Access device reservation system',
//     'id' : 14
// }, {
//     'name' : 'Instant Report',
//     'description' : 'Access device's instant report',
//     'id' : 15
// }, {
//     'name' : 'Reboot and Shutdown',
//     'description' : 'Reboot/Shutdown device',
//     'id' : 16
// }, {
//     'name' : 'Device Control Shortcut',
//     'description' : 'Access device's shortcuts(gps,wifi,etc.)',
//     'id' : 17
// }, {
//     'name' : 'Application Library Access',
//     'description' : 'Manage the applications on system.',
//     'id' : 20
// }, {
//     'name' : 'System Diagnosis',
//     'description' : 'Access system diagnosis.',
//     'id' : 21
// }, {
//     'name' : 'Ldap Configuration',
//     'description' : 'Manage ldap configuration for authentication.',
//     'id' : 22
// }, {
//     'name' : 'Role Management',
//     'description' : 'Manage user's roles on the system.',
//     'id' : 23
// }, {
//     'name' : 'Device Remote Control',
//     'description' : 'Control device's screen.',
//     'id' : 24
// }, {
//     'name' : 'User Management',
//     'description' : 'Manage users',
//     'id' : 25
// }, {
//     'name' : 'Supreme Device Management',
//     'description' : 'Manage device under all circumstances(test,reservation,management,etc.',
//     'id' : 27
// }, {
//     'name' : 'Turn Off and Reboot All Devices',
//     'description' : 'Turn off all device',
//     'id' : 28
// }, {
//     'name' : 'Licence Management',
//     'description' : 'Access licence management',
//     'id' : 29
// }, {
//     'name' : 'Mail Configuration',
//     'description' : 'Manage mail configuration system',
//     'id' : 30
// }, {
//     'name' : 'Access Automation',
//     'description' : 'Access to devices for automation',
//     'id' : 33
// }, {
//     'name' : 'Certificate Management',
//     'description' : 'Load-unload, activate-deactivate certificates',
//     'id' : 34
// }, {
//     'name' : 'Send File',
//     'description' : 'Send file to devices',
//     'id' : 31
// }, {
//     'name' : 'Group Management',
//     'description' : 'Manage group configuration, add devices and apps to groups.',
//     'id' : 35
// }, {
//     'name' : 'Force Automation',
//     'description' : 'Automation can start on device which used by someone manually',
//     'id' : 32
// }, {
//     'name' : 'Remote Debugging',
//     'description' : 'Access to devices for remote debugging',
//     'id' : 36
// }, {
//     'name' : 'System Parameters Management',
//     'description' : 'Manage system parameters',
//     'id' : 37
// }, {
//     'name' : 'Application Restrictions Management',
//     'description' : 'Manage application restrictions',
//     'id' : 38
// }, {
//     'name' : 'Automation Test Session Management',
//     'description' : 'Manage automation test sessions',
//     'id' : 39
// }, {
//     'name' : 'Manual Test Session Management',
//     'description' : 'Manage manual test sessions',
//     'id' : 40
// }, {
//     'name' : 'API Usages',
//     'description' : 'Access API Usages',
//     'id' : 41
// } ]
