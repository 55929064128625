import { Image } from 'semantic-ui-react';
import { VIDEO_PLAYER } from '../utils/UIMessages';

const MediaViewer = (props) => {

  return (
    <div id="media-viewer-container">
      {
        props.isVideo && <video src={props.src} type={props.type} controls>
          <p>{VIDEO_PLAYER.VIDEO_ERROR}</p>
        </video>
      }   
      {
        props.isImage && <Image src={props.src} bordered />
      }      
    </div>
  );
};

export default MediaViewer;
