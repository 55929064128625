import React, {Component} from 'react';
import {Confirm, Icon, Popup} from "semantic-ui-react";
import axios from "axios";
import {Flip, toast} from "react-toastify";
import {withTranslation} from "react-i18next";

import {headerForReports} from '../../utils/Methods';
import {showError} from "../../utils/ToastHelpers";
import {
    stopManual,
    stopDevelopment,
    getInstantUsage
} from '../../api/apiCalls'
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8';
import {getPrivileges} from '../../utils/Methods'
import { CANCEL_BUTTON, CONFIRM_BUTTON, CONNECTION_TERMINATED_MESSAGE, FAILED_ERROR_MESSAGE, instantUsageMessages, NOT_FETCH_DEVICES, SUCCESS_MESSAGE, TERMINATE_CONNECTION_MESSAGE, USER_HEADER, DEVICE_ID_HEADER, OS_VERSION_HEADER, OS_HEADER, MODEL_HEADER, BRAND_HEADER, TEST_TYPE_LABEL, START_TIME_LABEL, TEST_INFO_LABEL} from '../../utils/UIMessages';
import { REFRESH_ICON } from '../../utils/UiIcons';
import _ from 'lodash';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';

const username = localStorage.getItem('username');

class InstantUsage extends Component {

    stopDevelopmentTest = (deviceId) => {   
        toast.info(instantUsageMessages(null,deviceId).DEVELOPMENT_STOP_INFO);
        const params = {
            udId: deviceId
        }
        //axios.post('/api/device-connection/stop-development', null, {params})
        stopDevelopment(params).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                autoClose: 3000,
                transition: Flip,
            });
            this.getInstantUsages()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    };

    state = {
        deviceList: [],
        deviceListClone: [],
        allUsers: [],
        allDeviceIds: [],
        selectedUsersForFilter: "",
        selectedIdsForFilter: "",
        selectedUsernameForTerminate: "",
        selectedDeviceIdForTerminate: "",
        selectedTerminateType: "",
        confirmVisibility: false,
        privileges: [],
        filtered: [],
        loading: false
    }

    onConfirmVisible = (userName, deviceId, usageType) => {
        this.setState({
            selectedUsernameForTerminate: userName,
            selectedDeviceIdForTerminate: deviceId,
            selectedTerminateType: usageType
        }, () => this.setState({confirmVisibility: true}))
    }

    onTerminateConnection = () => {
        const params = {
            udId: this.state.selectedDeviceIdForTerminate,
            username: this.state.selectedUsernameForTerminate
        }
        //axios.post('/api/device-connection/stop-manual', null, {params})
        stopManual(params).then(() => {
            toast.success(CONNECTION_TERMINATED_MESSAGE(), {
                autoClose: 3000,
                transition: Flip,
            });
            this.getInstantUsages()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
        this.setState({
            selectedUsernameForTerminate: "",
            selectedDeviceIdForTerminate: "",
            confirmVisibility: false
        })

    }

    getInstantUsages = () => {
        this.setState({loading: true});
        //axios.get("/api/device-connection/busy-devices")
        getInstantUsage().then(res =>
            this.setState({deviceList: res.data})
        ).catch(err => {
            showError(NOT_FETCH_DEVICES() + err?.response?.data?.message ?? err.toString())
            this.setState({filtered: []})
        }).finally(() => this.setState({loading: false}));
    }

    stopAutomationTest = (deviceId) => {
        toast.info(instantUsageMessages(null,deviceId).AUTOMATION_STOP_INFO);
        axios.delete(`/api/grid/sessions/${deviceId}`).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                transition: Flip,
                autoClose: 3000
            });
            this.getInstantUsages()
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + this.props.t(err?.response?.data?.message ?? err.toString()))
        }).then(() => {
            this.setState({confirmVisibility: false})
        })
    };

    getTerminateFunction = () => {
        const terminateType = this.state.selectedTerminateType;
        if (terminateType === "Manual") {
            this.onTerminateConnection();
        } else if (terminateType === "Automation") {
            this.stopAutomationTest(this.state.selectedDeviceIdForTerminate);
        } else if (terminateType === "Development") {
            this.stopDevelopmentTest(this.state.selectedDeviceIdForTerminate);
        }
    }

    //New React Table V8 Columns
    columns = () => [
        {
            header: () => <div>X</div>,
            id: 'actions',
            size: 10,
            cell: info => {
                const instantUsage = info?.row.original;
                return ((instantUsage.usageType === 'Automation' && ((this.state.privileges.includes(PrivilegeConstants.ACCESS_AUTOMATION) && username === instantUsage.userName) ||
                            this.state.privileges.includes(PrivilegeConstants.REMOVE_GRID_SESSION))) ||
                                (instantUsage.usageType === "Manual" || instantUsage.usageType === "Development") && this.state.privileges.includes(PrivilegeConstants.SUPREME_DEVICE_MANAGEMENT)) &&
                                    <Popup wide position={'bottom left'}
                                        trigger={<Icon
                                            fitted
                                            circular
                                            link
                                            color='red'
                                            name='x'
                                            onClick={() => this.onConfirmVisible(instantUsage.userName, instantUsage.deviceId, instantUsage.usageType)}
                                        />}
                                        content={instantUsage.usageType === 'Automation' ?
                                            instantUsageMessages().AUTOMATION_POPUP_MESSAGE :
                                            instantUsage.usageType === 'Development' ?
                                                instantUsageMessages().DEVELOPMENT_POPUP_MESSAGE :
                                                instantUsageMessages(instantUsage.userName).POPUP_MESSAGE
                                        }
                                    />
            }
        },
        {
            header: () => <div>{START_TIME_LABEL()}</div>,
            accessorKey: 'startTime',
            id: 'startTime',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{USER_HEADER()}</div>,
            accessorFn: d => {
                return `${d.fullName} (${d.userName})`
            },
            id: 'user',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{DEVICE_ID_HEADER()}</div>,
            accessorKey: 'deviceId',
            id: 'deviceId',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{BRAND_HEADER()}</div>,
            accessorFn: d => _.capitalize(d.deviceBrand),
            id: 'deviceBrand',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{MODEL_HEADER()}</div>,
            accessorKey: 'deviceModel',
            id: 'deviceModel',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{OS_HEADER()}</div>,
            accessorFn: d => _.capitalize(d.deviceOs),
            id: 'deviceOs',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{OS_VERSION_HEADER()}</div>,
            accessorFn: d => _.capitalize(d.deviceOsVersion),
            id: 'deviceOsVersion',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{TEST_TYPE_LABEL()}</div>,
            accessorFn: d => _.capitalize(d.usageType),
            id: 'usageType',
            filterType: 'dropdown',
            cell: info => (<span>{info.getValue()}</span>)
        },
        {
            header: () => <div>{TEST_INFO_LABEL()}</div>,
            accessorKey: 'testInfo',
            id: 'testInfo',
            filterType: 'text',
            cell: info => (<span>{info.getValue()}</span>)
        }
    ];

    componentDidMount() {
        const privileges = getPrivileges();
        this.setState({privileges})
        this.getInstantUsages()
    }

    render() {
        return (
            <div className='main-container-report'>
                        <div className="main-right-header">
            <h2 style={{paddingLeft:8}}>{this.props.t(headerForReports())}</h2>
            <Popup wide position={"right center"}
                                       trigger={<Icon
                                        style={{paddingRight:8}}
                                           fitted
                                           link
                                           name={REFRESH_ICON}
                                           onClick={() => {
                                               this.getInstantUsages()
                                           }}
                                       />}
                                       content={instantUsageMessages().REFRESH_TABLE_POPUP}
                                />
        </div>
                <>
                    <ReactTableV8
                        data={this.state.deviceList}
                        columns={this.columns()}
                        columnwiseFilterableProp={true}
                        loadingProp={this.state.loading}
                    />

                    <Confirm
                        open={this.state.confirmVisibility}
                        onCancel={() => this.setState({
                            selectedUsernameForTerminate: "",
                            selectedDeviceIdForTerminate: "",
                            confirmVisibility: false
                        })}
                        onClose={() => this.setState({
                            selectedUsernameForTerminate: "",
                            selectedDeviceIdForTerminate: "",
                            confirmVisibility: false
                        })}
                        onConfirm={this.getTerminateFunction}
                        content={instantUsageMessages(this.state.selectedUsernameForTerminate).CONFIRM_TERMINATE}
                        header={TERMINATE_CONNECTION_MESSAGE()}
                        confirmButton={CONFIRM_BUTTON()}
                        cancelButton={CANCEL_BUTTON()}
                    />
                </>
            </div>
        );
    }

}

export default withTranslation()(InstantUsage);
