import React, {Component} from 'react';
import {
    Button,
    Confirm,
    Grid,
    Header,
    Icon,
    Image,
    Input,
    Label,
    List,
    Modal,
    Popup,
    Segment,
    Form,
    Dropdown,
    Tab
} from "semantic-ui-react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Notification from "../../utils/Notification";
import {Flip, toast} from "react-toastify";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import AppsInDeviceModal from "../../components/AppsInDeviceModal";
import {editPackageName, getIconForFile} from '../../utils/Methods';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';
import { DEBOUNCE_INPUT_MS, MIME_TYPES, EMBED_MODE, UPLOAD_FILE_MS } from "../../utils/Constants";
import {showError, showInfo, showSuccess} from "../../utils/ToastHelpers";
import SharedUrlModal from "../../components/SharedUrlModal"
import DeviceReservationInfo from '../../components/DeviceReservationInfo';
import ReservationModal from '../../components/ReservationModal';
import DebouncedInput from '../../components/ReactTableV8/DebouncedInput';
import FarmGenericPagination from '../../components/FarmGenericPagination';
import {
    getEmbeddedScreenShareToken,
    takeScreenshot,
    stopVideoRecord,
    startVideoRecord,
    startDeviceLog,
    stopDeviceLog,
    stopTrafficRecord,
    startTrafficRecord,
    updateToken, getAppList, getFileList, deployFile, getAppListForEmbedded, deviceReboot
} from '../../api/apiCalls';
import i18n from '../../translate/i18n';
import { LANGUAGE_COUNTRY_PATTERN, TOUCH_SCREEN} from '../../utils/Constants';
import { APPS_IN_THIS_DEVICE_HEADER, CANCEL_BUTTON, CLOSE_BUTTON, FAILED_ERROR_CODE_MESSAGE, FAILED_ERROR_MESSAGE, multiManageOperationMessages, NOT_FETCH_APPS, SEARCH_PLACEHOLDER, UNKNOWN_ERROR, UNSUPPORTED_TOKEN_TYPE_ERROR, VALUE_LABEL, YES_LABEL, APPLICATION_HEADER } from '../../utils/UIMessages';
import {
    ADD_ICON,
    AREA_GRAPH_ICON,
    CALENDAR_ALTERNATE_OUTLINE_ICON,
    CHECKMARK_ICON,
    CLOSE_ICON,
    CLOUD_UPLOAD_ICON,
    DONT_ICON,
    EXCHANGE_ICON,
    FILE_IMAGE_ICON,
    HAND_PEACE_ICON,
    HAND_SCISSORS_ICON,
    INFO_ICON,
    LOCK_ICON,
    MOBILE_ALTERNATE_ICON,
    MOUSE_POINTER_ICON,
    PHOTO_ICON,
    POWER_ICON,
    REDO_ICON,
    SHARE_ALTERNATE_ICON,
    SIDEBAR_ICON,
    SYNC_ICON,
    UNDO_ICON,
    UNORDERED_LIST_ICON,
    VIDEO_ICON,
    WORLD_ICON,
    X_ICON,
    HAND_POINT_UP,
    FOLDER_OPEN_OUTLINE_ICON,
    SPINNER_ICON,
} from "../../utils/UiIcons";
import SimulationResourceUpload from "./SimulationResourceUpload";
import MediaViewer from '../../components/MediaViewer';

class MultiManageOperation extends Component {

    constructor(props) {
        super(props);
        if (localStorage.getItem('lang')) {
            i18n.changeLanguage(localStorage.getItem('lang'));
        } else if (sessionStorage.getItem('lang')) {
            i18n.changeLanguage(sessionStorage.getItem('lang'));
        } else {
            i18n.changeLanguage('eng');
        }

        this.state = {
            wifi: undefined,
            gps: undefined,
            disabledButtons: false,

            openConfirm: false,

            openPortal: false,
            headerPortal: '',
            contentPortal: '',
            actionsPortal: '',

            rebootOrShutdown: '',

            groupedAppList: [],
            appList: [],
            fileLibraryList: [],
            files: [],
            selectedAppList: [],
            selectedFile: [],
            cloneSelectedAppList: [],
            loadingDeployment: false,
            deploymentModalVisibility: false,
            searchKeyword: '',
            isIconsExpanded: false,
            appsInDeviceModalVisibility: false,

            isRecordingVideo: this.props.location?.state?.isRecordingVideo,
            isRecordingLogs: this.props.location?.state?.isRecordingLogs,
            isRecordingTraffic: this.props.location?.state?.isRecordingTraffic,
            sharedUrlModalVisibility: false,
            sharedUrlView: '',
            sharedUrlManage: '',
            sharedUrlInspector: '',
            viewToken: '',
            manageToken: '',
            inspectorToken: '',
            showModalReservation: false,
            activeIndex: 0,
            targetDeviceLocale: null,
            isChangeDeviceLocaleDisabled: true,
            fileSearchInput: '',
            totalPagesFiles: 0,
            pageIndexFiles: 0,
            totalNumOfFiles: 0,
            currentDeviceUdId: '',
            privileges: [],
            simulationResModalVisibility: false,
            videoModalVisibility: false,
            videoSrc: null,
            videoLoading: true,
            isStoppingVideo: false
        };
    }

    actionButtonsRef = React.createRef();

    handleReboot = () => {
        deviceReboot(this.props.id).then(() => {
            showSuccess(multiManageOperationMessages().REBOOT_SUCCESSFUL_MESSAGE)
            setTimeout(() => {
                this.props.history.push("/device-list")
            }, 1000)
        }).catch((err) => {
            if (err?.response?.status === 500 || err?.response?.status === 404) {
                setTimeout(() => {
                    showError(`${multiManageOperationMessages().REBOOT_FAILED_MESSAGE} ${err?.response?.status}`);
                }, 2500);
            }
        })
    };

    handleShutdown = () => {
        axios.get("/api/screen-share/shutdown", {params: {udId: this.props.id}}).then(() => {
            showSuccess(multiManageOperationMessages().SHUTDOWN_SUCCESSFUL_MESSAGE)
            setTimeout(() => {
                this.props.history.push("/device-list");
            }, 1000)
        }).catch((err) => {
            if (err?.response?.status === 500 || err?.response?.status === 404) {
                setTimeout(() => {
                    showError(`${multiManageOperationMessages().SHUTDOWN_FAILED_MESSAGE} ${err?.response?.status}`);
                }, 2500);
            }
            showError(`${multiManageOperationMessages().SHUTDOWN_FAILED_MESSAGE} ${err?.response?.status}`);
        })
    };


    handleButtonColors(status) {
        if (status === true) {
            return "green"
        }
        if (status === false) {
            return "red"
        }
        if (status === undefined) {
            return "teal"
        } else {
            return ""
        }
    }

    handleDeviceGps = () => {
        axios.get("/api/screen-share/gps", {params: {udId: this.props.id}}).then((res) => {
            if (res.data === "Gps disable") {
                this.setState({gps: false});
                showSuccess(multiManageOperationMessages().GPS_DISABLED_MESSAGE)
            } else if (res.data === "Gps enable") {
                this.setState({gps: true});
                showSuccess(multiManageOperationMessages().GPS_ENABLED_MESSAGE)
            } else {
                this.setState({gps: undefined});
                toast.warn(UNKNOWN_ERROR(), {
                    autoClose: 3000,
                    transition: Flip,
                });
            }
        }).catch((err) => {
            console.log(err);
            showError(`${FAILED_ERROR_CODE_MESSAGE()} ${err?.response?.status}`);
        })

    };

    handleDeviceWifi = () => {
        axios.get("/api/screen-share/wifi", {params: {udId: this.props.id}}).then((res) => {
            if (res.data === "Wifi disable") {
                this.setState({wifi: false});
                showSuccess(multiManageOperationMessages().WIFI_DISABLED_MESSAGE)
            } else if (res.data === "Wifi enable") {
                this.setState({wifi: true});
                showSuccess(multiManageOperationMessages().WIFI_ENABLED_MESSAGE)
            } else {
                this.setState({wifi: undefined});
                toast.warn(UNKNOWN_ERROR(), {
                    autoClose: 3000,
                    transition: Flip,
                });
            }
        }).catch((err) => {
            console.log(err);
            showError(`${FAILED_ERROR_CODE_MESSAGE()} ${err?.response?.status}`);
        })

    };

    handleScreenShot = () => {
        if (this.props.testSessionField?.id) {
            //axios.post(`/api/test-session/${this.props.testSessionField?.id}/screenshot`)
            takeScreenshot(this.props.testSessionField?.id).then(() => {
                showInfo(multiManageOperationMessages().SCREENSHOT_TAKEN_MESSAGE, 2000)
            }).catch(err => {
                showError(`${multiManageOperationMessages().SCREENSHOT_NOT_TAKEN_ERROR_MESSAGE} ${err?.response?.status}`)
            })
        } else {
            this.setState({
                openPortal: true,
                headerPortal: multiManageOperationMessages().SCREENSHOT_HEADER_PORTAL,
                contentPortal: multiManageOperationMessages().SCREENSHOT_CONTENT_PORTAL
            }, () => {
                let headers;

                if (this.props.embedMode) {
                    const token = this.props.match.params;
                    headers = {'Embedded-Screen-Key': `${token.token}`}
                }
                axios.get("/api/screen-share/screenshot", {
                    ...(this.props.embedMode) && {headers},
                    responseType: "blob",
                    params: {udId: this.props.id}
                }).then(res => {
                    const dateTime = new Date().toISOString();
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', 'SS-' + this.props.id + '@' + dateTime + '.png');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }).catch(err => {
                    this.setState({
                        openPortal: true,
                        headerPortal: multiManageOperationMessages().SCREENSHOT_HEADER_PORTAL,
                        contentPortal: FAILED_ERROR_MESSAGE() + err.message
                    });

                })
            });
            this.clearPortal();
        }
    };

  generatePlayAndDownloadButtons = (recordId) => {
    return (
      <>
        <Button onClick={() => this.stopAndHandleRecord(recordId, true)}>
          {multiManageOperationMessages().STOP_AND_DOWNLOAD_BUTTON}
        </Button>
        <Button onClick={() => this.stopAndHandleRecord(recordId, false)}>
          {multiManageOperationMessages().STOP_AND_PLAY_BUTTON}
        </Button>
      </>
    );
  }

    handleScreenRecord = () => {
        if (this.props.testSessionField?.id) {
            if (this.state.isRecordingVideo) {
                this.setState({isStoppingVideo: true});
                //axios.delete(`/api/test-session/${this.props.testSessionField?.id}/video`)
                stopVideoRecord(this.props.testSessionField?.id).then(() => {
                    this.setState({isRecordingVideo: false}, () => showInfo(multiManageOperationMessages().VIDEO_RECORD_STOPPED_MESSAGE, 2000))
                }).catch(err => {
                 showError(`${multiManageOperationMessages().VIDEO_RECORD_NOT_STOPPED_ERROR_MESSAGE} ${err?.response?.status}`)
                }).finally(() => {
                    this.setState({isStoppingVideo: false});
                })
            } else {
                //axios.post(`/api/test-session/${this.props.testSessionField?.id}/video`)
                startVideoRecord(this.props.testSessionField?.id).then(() => {
                    this.setState({isRecordingVideo: true}, () => showInfo(multiManageOperationMessages().VIDEO_RECORD_STARTED_MESSAGE, 2000))
                }).catch(err => {
                    showError(`${multiManageOperationMessages().VIDEO_RECORD_NOT_STARTED_ERROR_MESSAGE} ${err?.response?.status}`)
                })
            }
        } else {
            let headers;
            if (this.props.embedMode) {
                const token = this.props.match.params;
                headers = {'Embedded-Screen-Key': `${token.token}`}
            }
            const url = "/api/screen-share/";
            axios.get(url + "start-record", {
                ...(this.props.embedMode) && {headers},
                params: {udId: this.props.id}}
            ).then((res) => {
                if (res.data.status === "SUCCESS") {
                    let recordId = res.data.recordId;
                    this.setState({
                        openPortal: true,
                        headerPortal: multiManageOperationMessages().SCREEN_RECORDING_HEADER_PORTAL,
                        contentPortal: multiManageOperationMessages().SCREEN_RECORDING_CONTENT_PORTAL,
                        actionsPortal: this.generatePlayAndDownloadButtons(recordId)
                    })
                } else {
                    this.setState({
                        openPortal: true,
                        headerPortal: multiManageOperationMessages().SCREEN_RECORDING_HEADER_PORTAL,
                        contentPortal: multiManageOperationMessages().SCREEN_RECORDING_NOT_SUCCESS_CONTENT_PORTAL
                    });
                    this.clearPortal()
                }
            }).catch((err) => {
                this.setState({
                    openPortal: true,
                    headerPortal: multiManageOperationMessages().SCREEN_RECORDING_HEADER_PORTAL,
                    contentPortal: FAILED_ERROR_MESSAGE() + err.message
                });
                this.clearPortal()
            })
        }
    };

    stopAndHandleRecord = (recordId, isDownload) => {
        let headers;
        if (this.props.embedMode) {
            const token = this.props.match.params;
             headers = {'Embedded-Screen-Key': `${token.token}`}
        }
        const url = "/api/screen-share/";
        let videoName;
        this.setState({videoModalVisibility: true, videoLoading: true});
        axios.get(url + "stop-record", {
            ...(this.props.embedMode) && {headers},
            params: {
                udId: this.props.id,
                recordId: recordId
            }
        }).then((res1) => {
            videoName = res1.data.videoName;
            axios.get(url + "download-record", {
                ...(this.props.embedMode) && {headers},
                responseType: "blob",
                params: {file: videoName}}
            ).then((res2) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res2.data],
                  {type: MIME_TYPES.VIDEO_MP4}));
                this.setState({})
                if(isDownload){
                  const link = document.createElement('a');
                  link.href = downloadUrl;
                  link.setAttribute('download', `${videoName}`);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  this.setState({videoModalVisibility: false, videoLoading: false});
                } else{
                    this.setState({videoSrc: downloadUrl, videoLoading: false});
                }

              this.setState({
                    openPortal: false,
                    headerPortal: "",
                    contentPortal: "",
                    actionsPortal: ""
                })
            }).catch((err) => {
                this.setState({
                    openPortal: true,
                    headerPortal:  multiManageOperationMessages().SCREEN_RECORDING_HEADER_PORTAL,
                    contentPortal: FAILED_ERROR_MESSAGE() + err.message
                });
                this.clearPortal()
            })
        }).catch((err) => {
                this.setState({
                    openPortal: true,
                    headerPortal: multiManageOperationMessages().SCREEN_RECORDING_HEADER_PORTAL,
                    contentPortal: FAILED_ERROR_MESSAGE() + err.message
                });
                this.clearPortal()
            }
        )
    }


    clearPortal = () => {
        setTimeout(() => {
            this.setState({
                openPortal: false,
                headerPortal: "",
                contentPortal: "",
                actionsPortal: ""
            })
        }, 3500)
    }

    onConfirmAction = () => {
        if (this.state.rebootOrShutdown === "Reboot") {
            this.handleReboot()
        } else if (this.state.rebootOrShutdown === "Shutdown") {
            this.handleShutdown()
        }
    };

    onSubmitStatisticsByApp = (packageName) => {
        const params = {
            deviceId: this.props.id,
            packageName: packageName.slice(0, -4)
        };
        axios.get("/api/apk/info", {params, responseType: 'arraybuffer'}).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/zip'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', packageName.slice(0, -4) + "-" + this.props.id + '-usage.zip');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(() => {
            showError(multiManageOperationMessages(packageName).PACKAGE_NOT_FOUND_APP_LIBRARY);
        })
    };

    onDeployApplication = (appId) => {
        this.setState({loadingDeployment: true}, () => {
            const data = {
                deviceList: [this.props.id],
                appList: [{appId, type: this.props.deviceOs?.toLowerCase() === 'android' ? 'Android' : 'iOS'}]
            }
            let headers;
            if (this.props.embedMode) {
                const token = this.props.match.params;
                headers = {'Embedded-Screen-Key': `${token.token}`}
            }
            axios.post('/api/apk/install', data, {...(this.props.embedMode) && {headers}}).then(res => {
                if ((res.data[0]?.apps[0]?.status === "APP INSTALLED SUCCESSFULLY") || (res.data[0]?.apps[0]?.status === "ok") || (res.data[0]?.apps[0]?.status === "OK")) {
                    showSuccess(multiManageOperationMessages().APP_SUCCESSFULLY_INSTALLED_TO_DEVICE_MESSAGE,5000)
                    this.setState({
                        loadingDeployment: false,
                        deploymentModalVisibility: false,
                        selectedAppList: [],
                        searchKeyword: ''
                    })
                } else {
                    showError(`${multiManageOperationMessages().DEPLOYMENT_FAILED_ERROR_MESSAGE} ${this.props.t(res.data[0]?.apps[0]?.status.toString())}`);
                    this.setState({
                        loadingDeployment: false,
                        deploymentModalVisibility: false,
                        selectedAppList: [],
                        searchKeyword: ''
                    })
                }

            }).catch(err => {
                showError(`${multiManageOperationMessages().DEPLOYMENT_FAILED_UNKNOWN_ERROR_MESSAGE} ${err?.response?.data?.message ?? err.toString()}`);
                this.setState({
                    loadingDeployment: false,
                    deploymentModalVisibility: false,
                    selectedAppList: [],
                    searchKeyword: ''
                })
            })
        })
    }

    handleUploadFile = async fileId => {
        try {
            await deployFile(this.state.currentDeviceUdId, fileId);
            this.setState({
                selectedFile: []
            })
            showSuccess(`${multiManageOperationMessages().FILE_HAS_BEEN_SUCESSFULLY_DEPLOYED}`);
        } catch (err) {
            showError(`${multiManageOperationMessages().DEPLOYMENT_FAILED_ERROR_MESSAGE} ${err?.response?.data?.message ?? err.toString()}`)
        }
    }

    onSearchAppVersion = (e, {value}) => {
        const {cloneSelectedAppList} = this.state
        this.setState({searchKeyword: value}, () => {
            const selectedAppList = cloneSelectedAppList.filter(app => {
                const partialValuesToCheck = [app.appVersion, app.appVersionCode, app.appVersionName, app.appCategory];
                const exactValuesToCheck = [app.id];

                return partialValuesToCheck.some(val => val?.toLowerCase().startsWith(value.toLowerCase()))
                    || exactValuesToCheck.some(val => val?.toString() === value);

            })
            this.setState({selectedAppList})
        })
    }

    fetchApplicationList = () => {
        let promiseObject
        if (this.props.embedMode) {
            const token = this.props.match.params;
            const headers = {'Embedded-Screen-Key': `${token.token}`}
            promiseObject = getAppListForEmbedded({headers})
        } else {
            promiseObject = getAppList();
        }
        promiseObject.then(response => {
            if (response.data) {
                this.setState({appList: response.data})
                let groupBy = function (xs, key) {
                    return xs.reduce(function (rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                };
                let appList = _.sortBy(response.data, function (o) {
                    return o.appName?.toLocaleLowerCase()
                })
                let allApps = editPackageName(appList)
                let groupedByPackage = groupBy(allApps, 'appPackage');
                this.setState({groupedAppList: groupedByPackage})
            }
        }).catch(err => {
            showError(`${NOT_FETCH_APPS()} ${err?.response?.data?.message ?? err.toString()}`);
        })
    }

    //Method for fetching file list from backend with search value
    fetchFileList = async value => {
        //No search default
        if(this.state.fileSearchInput.length === 0){
            try{
                const res = await getFileList({ page: this.state.pageIndexFiles, size: 7 });
                this.setState({
                        fileLibraryList: res.data.content,
                        files: res.data.content,
                        totalPagesFiles: res.data.totalPages,
                        pageIndexFiles: res.data.pageable.pageNumber,
                        totalNumOfFiles: res.data.totalElements
                    });
            } catch(err) {
                showError(`${multiManageOperationMessages().COULD_NOT_GET_THE_FILE_LIST} ${err?.response?.data?.message ?? err.toString()} `);
            }
            //On search
        } else{
            try{
                const res = await getFileList(value && {name:value});
                //Matching search
                if(res.data.content !== 0){
                    this.setState({
                        files: res.data.content,
                        totalPagesFiles: res.data.totalPages,
                        pageIndexFiles: res.data.pageable.pageNumber,
                        totalNumOfFiles: res.data.totalElements
                    });
                }
            } catch(err) {
                showError(`${multiManageOperationMessages().COULD_NOT_GET_THE_FILE_LIST} ${err?.response?.data?.message ?? err.toString()} `);
            }
        }
    }

    componentDidMount() {
        this.fetchApplicationList();
        this.setState({currentDeviceUdId: this.props.selectedDevice.deviceId, privileges: this.props.privileges});
    }

    actionButtonClick = () => {
        this.setState(prevState => ({isIconsExpanded: !prevState.isIconsExpanded}), () => {
            const actionButtons = this.actionButtonsRef.current.querySelectorAll('.actionButton');
            let delay = this.state.isIconsExpanded ? 0 : 300;
            for (let i = 0; i < actionButtons.length; i++) {
                if (!this.state.isIconsExpanded) {
                    actionButtons[i].style.transform = "scale(0)";
                    delay = delay - 30;
                } else {
                    actionButtons[i].style.transform = "scale(1)";
                    delay = delay + 30;
                }
                actionButtons[i].style.transition = "all 200ms " + delay + "ms";
            }
        });
    }


    toggleDetailedInfoId = () => {
      this.props.setDetailedInfoId(this.props.detailedInfoId === this.props.id ? null : this.props.id);
    }

    onRecordDeviceLogs = () => {
        if (this.state.isRecordingLogs) {
            //axios.delete(`/api/test-session/${this.props.testSessionField?.id}/devicelog`)
            stopDeviceLog(this.props.testSessionField?.id).then(() => {
                this.setState({isRecordingLogs: false}, () => showInfo(multiManageOperationMessages().DEVICE_LOG_RECORD_STOPPED_MESSAGE, 2000))
            }).catch(err => {
                showError(`${multiManageOperationMessages().DEVICE_LOG_RECORD_NOT_STOPPED_ERROR_MESSAGE} ${err?.response?.status}`)
            })
        } else {
            //axios.post(`/api/test-session/${this.props.testSessionField?.id}/devicelog`)
            startDeviceLog(this.props.testSessionField?.id).then(() => {
                this.setState({isRecordingLogs: true}, () => showInfo(multiManageOperationMessages().DEVICE_LOG_RECORD_STARTED_MESSAGE))
            }).catch(err => {
                showError(`${multiManageOperationMessages().DEVICE_LOG_RECORD_NOT_STARTED_ERROR_MESSAGE} ${err?.response?.status}`)
            })
        }

    }

    onRecordNetworkTraffic = () => {
        if (this.state.isRecordingTraffic) {
            //axios.delete(`/api/test-session/${this.props.testSessionField?.id}/trafficrecord`)
            stopTrafficRecord(this.props.testSessionField?.id).then(() => {
                this.setState({isRecordingTraffic: false}, () => showInfo(multiManageOperationMessages().NETWORK_TRAFFIC_RECORD_STOPPED_MESSAGE, 2000))
            }).catch(err => {
                showError(`${multiManageOperationMessages().NETWORK_TRAFFIC_RECORD_NOT_STOPPED_ERROR_MESSAGE} ${err?.response?.status}`)
            })
        } else {
            //axios.post(`/api/test-session/${this.props.testSessionField?.id}/trafficrecord`)
            startTrafficRecord(this.props.testSessionField?.id).then(() => {
                this.setState({isRecordingTraffic: true}, () => showInfo(multiManageOperationMessages().NETWORK_TRAFFIC_RECORD_STARTED_MESSAGE, 2000))
            }).catch(err => {
                showError(`${multiManageOperationMessages().NETWORK_TRAFFIC_RECORD_NOT_STARTED_ERROR_MESSAGE} ${err?.response?.status}`)
            })
        }
    }

    handleEmbeddedScreen = () => {
        //axios.get("/api/embedded-screen-share/token",data)
        getEmbeddedScreenShareToken(this.props.id).then(response => {
            this.setState({
                sharedUrlModalVisibility: true,
                viewToken: response.data.accessToken,
                manageToken: response.data.manageToken,
                inspectorToken: response.data.inspectorToken,
                sharedUrlView: `${window.location.origin}/embed-view/${response.data.accessToken}`,
                sharedUrlManage: `${window.location.origin}/embed-manage/${response.data.manageToken}`,
                sharedUrlInspector: `${window.location.origin}/embed-inspector/${response.data.inspectorToken}`
            });
        }).catch((err) => {
            showError(`${this.props.t(err?.response?.data?.message ?? err.toString())} ${multiManageOperationMessages().REFRESH_TOKEN_ERROR_MESSAGE} ${err?.response?.status}`);
        })
    };

    refreshToken = async (type) => {
        try {
            if (type === 'view') {
                const response = await updateToken(this.props.id,type);
                this.setState({
                    viewToken: response.data.accessToken,
                    sharedUrlView: `${window.location.origin}/embed-view/${response.data.accessToken}`
                })
            } else if (type === 'manage') {
                const response = await updateToken(this.props.id,type);
                this.setState({
                    manageToken: response.data.manageToken,
                    sharedUrlManage: `${window.location.origin}/embed-manage/${response.data.manageToken}`
                })
            } else if (type === 'inspector') {
                const response = await updateToken(this.props.id,type);
                this.setState({
                    inspectorToken: response.data.inspectorToken,
                    sharedUrlInspector: `${window.location.origin}/embed-inspector/${response.data.inspectorToken}`
                })
            } else {
                showError(`${UNSUPPORTED_TOKEN_TYPE_ERROR()}`);
            }

        } catch (err) {
            showError(`${this.props.t(err?.response?.data?.message ?? err.toString())} ${multiManageOperationMessages().REFRESH_TOKEN_ERROR_MESSAGE} ${err?.response?.status}`);
        }
    };

    onShowReservationModal = () => {
        this.setState({showModalReservation: true});
    };

    handleTargetDeviceLocaleInput = data => {
        this.setState({targetDeviceLocale: data.value}, () => {
            this.setState({isChangeDeviceLocaleDisabled: !LANGUAGE_COUNTRY_PATTERN.test(this.state.targetDeviceLocale)})
        })
    }

    onPageChangeFiles = activePage => {
        this.setState({ pageIndexFiles: activePage }, () => this.fetchFileList());
    }


    deploymentTabPanes = () => [{
        menuItem: APPLICATION_HEADER(),
        render: () =>
        ([
            PrivilegeConstants.APPLICATION_MANAGEMENT.LIBRARY_ACCESS,
            PrivilegeConstants.APPLICATION_MANAGEMENT.UPLOAD_TO_LIB
        ].every(privilege => this.state.privileges.includes(privilege))) || this.props.embedMode === EMBED_MODE.MANAGE ? (<Tab.Pane>
            <Modal.Content>
                        <Segment basic loading={this.state.loadingDeployment} padded>
                            <Grid divided={'vertically'}>
                                <Grid.Row columns={2} centered>
                                    <Grid.Column>
                                        <Header size={'small'} style={{textAlign: 'center'}}>{this.props.t(multiManageOperationMessages().ALL_APPLICATIONS_HEADER)}</Header>
                                        <Segment basic id='segment-default'>
                                            <List divided verticalAlign='middle' style={{minWidth: '10vw'}}>
                                                {Object.keys(this.state.groupedAppList).map((app, i) => {
                                                    if (this.state.groupedAppList[app][0].appOs.toLowerCase() !== this.props.deviceOs.toLowerCase()) {
                                                        return <React.Fragment key={i}></React.Fragment>
                                                    } else {
                                                        return (<List.Item key={i}>
                                                            <List.Content floated='right'>
                                                                <Button onClick={() => {
                                                                    this.setState({
                                                                        selectedAppList: this.state.groupedAppList[app],
                                                                        cloneSelectedAppList: this.state.groupedAppList[app],
                                                                        searchKeyword: ''
                                                                    })
                                                                }} icon='arrow right'/>
                                                            </List.Content>
                                                            <Image size='mini'
                                                                   src={'data:image/png;base64,' + this.state.groupedAppList[app][0].appIcon}
                                                                   alt='appIcon'/>
                                                            <List.Content>
                                                                {this.state.groupedAppList[app][0].appName}
                                                            </List.Content>
                                                        </List.Item>)
                                                    }
                                                })}
                                            </List>
                                        </Segment>
                                    </Grid.Column>

                                    <Grid.Column>
                                        {
                                            this.state.selectedAppList.length !== 0 ? <>
                                                <Input fluid placeholder={SEARCH_PLACEHOLDER} value={this.state.searchKeyword}
                                                       onChange={this.onSearchAppVersion}
                                                       disabled={_.isEmpty(this.state.selectedAppList) && this.state.searchKeyword === ''}/>
                                                <Segment basic id='segment-filled'>
                                                    <List divided verticalAlign='middle' style={{minWidth: '11vw'}}>
                                                        {_.orderBy(this.state.selectedAppList, ['id', 'appVersion'], ['desc', 'desc']).map((app1, i2) => {
                                                            return(<List.Item key={i2} style={{marginTop: 15}}>
                                                                <List.Content>
                                                                    <List.Header>#{app1.id} {app1.appName} v{app1.appVersion}</List.Header>
                                                                    <List.Description>
                                                                        <div style={{marginTop: 3}}><b>{this.props.t(multiManageOperationMessages().VERSION_CODE)}:</b> {app1.appVersionCode}</div>
                                                                        <div style={{marginTop: 3}}><b>{this.props.t(multiManageOperationMessages().VERSION_NAME)}:</b> {app1.appVersionName ? app1.appVersionName : 'N/A'}</div>
                                                                        <div style={{marginTop: 3}}><b>{this.props.t(multiManageOperationMessages().CATEGORY)}:</b> {app1.appCategory}</div>
                                                                        <div style={{marginTop: 3}}><b>{this.props.t(multiManageOperationMessages().HARDWARE_SIMULATE)}:</b> {app1.hardwareSimulate ? 'Yes':'No'}</div>
                                                                        <div><Button compact fluid content={this.props.t(multiManageOperationMessages().DEPLOY)}
                                                                                     onClick={() => {
                                                                                         this.onDeployApplication(app1.id)
                                                                                     }} icon='upload'/></div>
                                                                    </List.Description>
                                                                </List.Content>
                                                            </List.Item>)
                                                        })}
                                                    </List>
                                                </Segment>
                                            </> : <>
                                                <Input fluid placeholder={SEARCH_PLACEHOLDER} value={this.state.searchKeyword}
                                                    onChange={this.onSearchAppVersion}
                                                    disabled={this.state.searchKeyword === ''} />
                                                {!_.isEmpty(this.state.searchKeyword) ?
                                                    <Segment placeholder attached={"top"} id='segment-default'>
                                                        <Header icon>
                                                            <Icon name={FOLDER_OPEN_OUTLINE_ICON}/>
                                                            {multiManageOperationMessages().NO_APPLICATION_FOUND_FOR_SEARCH}
                                                        </Header>
                                                    </Segment> :
                                                    <Segment placeholder id='segment-default'>
                                                        <Header icon>
                                                            <Icon name={HAND_POINT_UP} />
                                                            {multiManageOperationMessages().SELECT_APP_FROM_LIST_LABEL}
                                                        </Header>
                                                    </Segment>}
                                            </>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
            </Modal.Content>
        </Tab.Pane>) : (<Segment basic id='segment-default' style={{overflow: 'auto', height: 'calc(60vh)', maxHeight: 'calc(60vh)', padding: 0}}>
            <h1 id='error-msg-styling'>{multiManageOperationMessages().YOU_DO_NOT_HAVE_PERMISSION_TO_DEPLOY_APPLICATION_TO_DEVICE}</h1>
        </Segment>)
    },
    !this.props.embedMode && { // hide for embedded mode
        menuItem: multiManageOperationMessages().FILES,
        render: () =>
        ([
            PrivilegeConstants.FILE_MANAGEMENT.LIST,
            PrivilegeConstants.FILE_MANAGEMENT.DEPLOY
        ].every(privilege => this.state.privileges.includes(privilege))) ? (
            (<Tab.Pane>
            <Modal.Content>
            <Segment basic loading={this.state.loadingDeployment} padded>
                <Grid divided={'vertically'}>
                    {
                        (this.state.fileLibraryList.length !== 0) ? (<Grid.Row columns={2} centered>
                        <Grid.Column>
                                <Segment basic id='segment-default'>
                            <>
                                <Header size='small' style={{textAlign: 'center'}}>{multiManageOperationMessages.ALL_FILES}</Header>
                                <DebouncedInput
                                    iconProp={true}
                                    valueProp={this.state.fileSearchInput}
                                    onChangeProp={value => {
                                        this.setState({ fileSearchInput: value }, () => this.fetchFileList(value));
                                    }}
                                    className='debounce file-deploy-debounce'
                                    debounceProp={DEBOUNCE_INPUT_MS}
                                    placeHolderProp={multiManageOperationMessages().SEARCH_A_FILE}
                                    onKeyUpProp={e => {
                                        if (e.keyCode === 32) {
                                            e.target.value = e.target.value + ' '
                                            e.stopPropagation()
                                        }
                                    }}
                                />
                                {
                                    this.state.files.length === 0 ? (
                                    <h3 id='error-msg-styling'>{multiManageOperationMessages().NO_FILE_MATCH}</h3>
                                ) : (
                                    <List divided verticalAlign='middle' style={{minWidth: '10vw'}}>
                                    {(this.state.files).map((file, i)=>{
                                        return (
                                            <List.Item key={i}>
                                                <List.Content floated='right'>
                                                    <Button onClick={() => {
                                                        this.setState({selectedFile: file})
                                                    }} icon='arrow right'/>
                                                </List.Content>

                                                <List.Content className='file-deploy-content'>
                                                    <span style={{marginBottom: '0.5rem'}}>{getIconForFile(((file.fileName).split('.').pop()), 'big')}</span>
                                                    <span className='deploy-content-span'>{file.name}</span>
                                                </List.Content>
                                            </List.Item>)
                                        })
                                    }
                                </List>
                                )}
                                <FarmGenericPagination
                                    activePageProp={this.state.pageIndexFiles + 1}
                                    pageIndexProp={this.state.pageIndexFiles}
                                    totalPagesProp={this.state.totalPagesFiles}
                                    onPageChangeProp={this.onPageChangeFiles}
                                    classNameProp='library-pagination file-deploy-pagination'

                                />
                            </>
                            </Segment>
                        </Grid.Column>

                        <Grid.Column>
                            {/*Checking a file/files selected or not*/}
                            {
                            this.state.selectedFile.length !== 0 ? <div>
                                    <Segment id='segment-content'>
                                        <div className='file-detail-card'><b>{multiManageOperationMessages().NAME}:</b> {this.state.selectedFile.name}</div>
                                        <div className='file-detail-card'><b>{multiManageOperationMessages().FILE_NAME}:</b> {this.state.selectedFile.fileName}</div>
                                        <div className='file-detail-card'><b>{multiManageOperationMessages().UPLOADER}:</b> {this.state.selectedFile.uploader}</div>
                                        <div><Button compact fluid content={multiManageOperationMessages().DEPLOY}
                                            onClick={() => {
                                                this.setState({loadingDeployment: true}, ()=>{
                                                    setTimeout(() => {
                                                        this.setState({loadingDeployment: false});
                                                        this.handleUploadFile(this.state.selectedFile.id);
                                                    }, UPLOAD_FILE_MS)
                                                })
                                            }} icon='upload'/></div>
                                    </Segment>
                            </div> : (
                            <Segment placeholder id='segment-default'>
                                <Header icon>
                                    <Icon name={HAND_POINT_UP} />
                                    {multiManageOperationMessages().SELECT_A_FILE_FROM_LIST}
                                </Header>
                            </Segment>)
                            }

                        </Grid.Column>
                    </Grid.Row>) : (<Segment basic id='segment-filled'><h2 style={{textAlign: 'center', marginLeft: '14rem', marginTop: '5rem'}}>{multiManageOperationMessages().THERE_ARE_NO_FILES_ON_LIBRARY}</h2></Segment>)}
                </Grid>
            </Segment>
        </Modal.Content>
        </Tab.Pane>)
        ) : (<Segment basic id='segment-default'>
            <h1 id='error-msg-styling'>{multiManageOperationMessages().YOU_DO_NOT_HAVE_PERMISSION_TO_SEE_DEPLOY_FILE_TO_DEVICE}</h1>
        </Segment>)
    }];

    render() {
        return (
            <div className='device-actions' ref={this.actionButtonsRef}>
                {!this.props.embedMode && <Popup
                    position='right center'
                    content={multiManageOperationMessages(null,this.props.detailedInfoId === this.props.id ? 'Close' : 'Show').OPEN_OR_CLOSE_DETAILED_DEVICE_INFO}
                    trigger={<Button  color='blue'
                                     icon={this.props.detailedInfoId === this.props.id ? CLOSE_ICON : INFO_ICON}
                                     onClick={this.toggleDetailedInfoId}/>}>
                </Popup>}
                {!this.props.embedMode && !this.props.testSessionField?.id && this.props.connectedDeviceCount > 1 &&
                  <Popup
                      position='right center'
                      content={multiManageOperationMessages().REMOVE_DEVICE_POPUP}
                      trigger={<Button  color='red' icon={DONT_ICON}
                      onClick={() => this.props.removeFromConnectedDeviceIdList(this.props.id)} />}>
                  </Popup>
                }
                {/*need discuss for embed mode*/}
                {(this.props.embedMode ? true : (this.props.testSessionField?.id ?
                    (this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT) ||
                        this.props.privileges.includes(PrivilegeConstants.DEVICE_CONTROL)) :
                    this.props.privileges.includes(PrivilegeConstants.MULTI_TOUCH))) &&
                    <Popup content={multiManageOperationMessages().SCREEN_RECORD_POPUP}
                           position='right center'
                           trigger={
                               <Button
                                       color={this.props.testSessionField?.id ? (this.state.isRecordingVideo ? 'green' : 'white') : 'white'}
                                       icon={this.state.isStoppingVideo ? SPINNER_ICON: VIDEO_ICON}
                                       disabled={this.state.isStoppingVideo}
                                       loading={this.state.isStoppingVideo}
                                       onClick={() => {
                                           this.handleScreenRecord()
                                       }}/>
                           }
                    />}
                {/*RBA check?*/}
                {/*need discuss for embed mode*/}
                {(this.props.embedMode ? true : ((this.props.testSessionField?.id ?
                    (this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT) ||
                        this.props.privileges.includes(PrivilegeConstants.DEVICE_CONTROL)) :
                    this.props.privileges.includes(PrivilegeConstants.MULTI_TOUCH)))) &&
                    <Popup content={multiManageOperationMessages().TAKE_SCREENSHOT_POPUP}
                           position='right center'
                           trigger={
                               <Button  icon={PHOTO_ICON}
                                       onClick={() => this.handleScreenShot()}
                               />
                           }
                    />}

                {this.props.testSessionField?.id &&
                    (this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT) ||
                        this.props.privileges.includes(PrivilegeConstants.DEVICE_CONTROL)) &&
                <Popup content={multiManageOperationMessages().SAVE_DEVICE_LOGS_POPUP}
                       position='right center'
                       trigger={
                           <Button  color={(this.state.isRecordingLogs ? 'green' : 'white')} icon={SIDEBAR_ICON}
                                   onClick={() => this.onRecordDeviceLogs()}
                           />
                       }
                />}

                {this.props.testSessionField?.id &&
                    (this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT) ||
                        this.props.privileges.includes(PrivilegeConstants.DEVICE_CONTROL)) &&
                <Popup content={multiManageOperationMessages().SAVE_TRAFFIC_NETWORK_POPUP}
                       position='right center'
                       trigger={
                           <Button  color={(this.state.isRecordingTraffic ? 'green' : 'white')} icon={EXCHANGE_ICON}
                                   onClick={() => this.onRecordNetworkTraffic()}
                           />
                       }
                />}

                {(this.props.embedMode ? true : (this.props.privileges.includes(PrivilegeConstants.MULTI_TOUCH))) && <Popup content={multiManageOperationMessages().TURN_SCREEN_POPUP}
                                                                position='right center'
                                                                trigger={
                                                                    <Button
                                                                            icon={this.props.deviceScreenState === "1" ? REDO_ICON : UNDO_ICON}
                                                                            onClick={this.props.handleRotate}/>
                                                                }
                />}
                <Popup
                    flowing
                    on={['hover', 'click']}
                    hoverable
                    position='right center'
                    trigger={<Button  icon={FILE_IMAGE_ICON}/>}>
                    <Label>{multiManageOperationMessages().VIDEO_QUALITY_LABEL}</Label>
                    <div><input type="range" min={0} max={1} step={0.1} onChange={this.props.handleQuality}
                                value={this.props.qualityValue}/></div>
                    <div style={{textAlign: "center"}}>{VALUE_LABEL()}: {this.props.qualityValue * 10}</div>
                </Popup>
                {!_.isEmpty(this.state.groupedAppList) && this.props.deviceOs?.toLowerCase() === 'android' && this.props.privileges.includes(PrivilegeConstants.MULTI_TOUCH) &&
                <Popup
                    size='large'
                    flowing
                    on={['hover', 'click']}
                    hoverable
                    position='right center'
                    trigger={<Button  icon={AREA_GRAPH_ICON}/>}>
                    <Header as={"h3"}>{multiManageOperationMessages().BATTERY_CPU_MEMORY_USAGE_LABEL}</Header>
                    <List divided selection animated verticalAlign='middle'>
                        {Object.keys(this.state.groupedAppList).map((app, i) => {
                            if (this.state.groupedAppList[app][0].appOs === 'ios') {
                                return <React.Fragment key={i}></React.Fragment>
                            } else {
                                return (<List.Item key={i}>
                                    <Label as="a" image size="large" onClick={() => {
                                        this.onSubmitStatisticsByApp(app)
                                    }}>
                                        <img src={'data:image/png;base64,' + this.state.groupedAppList[app][0].appIcon}
                                             alt='appIcon'/>
                                        {this.state.groupedAppList[app][0].appName}

                                    </Label>
                                </List.Item>)
                            }
                        })}
                    </List>
                </Popup>
                }
                {/*need discuss for embed mode*/}
                {(this.props.embedMode ? true : (PrivilegeConstants.MULTI_TOUCH)) && <Popup
                    content={multiManageOperationMessages().MULTI_TOUCH_POPUP}
                    flowing
                    on={['hover', 'click']}
                    hoverable
                    position='right center'
                    trigger={<Button
                    icon={HAND_SCISSORS_ICON}
                    color={this.props.multiTouch ? 'blue' : 'white'}
                    onClick={()=>{
                        this.props.resetHandleMultiTouch();
                        this.props.multiTouchToggle();
                    }}
                    />}>
                    <div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Icon name={HAND_PEACE_ICON} className='multitouch-icons'/>
                            <Dropdown
                            fluid
                            selection
                            options={TOUCH_SCREEN.NUMBER_OF_FINGERS}
                            name={'numberOfFingers'}
                            value={this.props.numberOfFinger}
                            onChange={this.props.handleFingerMultiTouch}
                            style={{marginBottom: '1rem'}}
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Icon name={MOUSE_POINTER_ICON} className='multitouch-icons'/>
                            <Dropdown
                                fluid
                                selection
                                options={TOUCH_SCREEN.NUMBER_OF_CLICKS}
                                name={'numberOfClicks'}
                                value={this.props.numberOfClick}
                                onChange={this.props.handleClickMultiTouch}
                                style={{marginBottom: '1rem'}}
                            />
                        </div>
                    </div>
                </Popup>
                }
                {this.props.testSessionField?.id &&
                <Popup content={multiManageOperationMessages(null,this.props.isSmartScreenshotOn).TURN_SMART_SCREENSHOT_POPUP}
                       position='right center'
                       trigger={<Button  color={this.props.isSmartScreenshotOn ? 'green' : 'white'}
                                  icon={MOBILE_ALTERNATE_ICON} onClick={this.props.toggleSmartScreenshot} /> }
                />}
                {(!_.isEmpty(this.state.appList) && (this.props.embedMode === EMBED_MODE.MANAGE || this.props.privileges.includes(PrivilegeConstants.APPLICATION_MANAGEMENT.INSTALL))) &&  <Popup
                    flowing
                    on={['hover', 'click']}
                    hoverable
                    position='right center'
                    trigger={<Button
                                     onClick={() => {
                                         this.fetchApplicationList()
                                         this.setState({deploymentModalVisibility: true})
                                     }}
                                     icon={CLOUD_UPLOAD_ICON}/>}>
                    {multiManageOperationMessages().APPLICATION_DEPLOYMENT_POPUP}
                </Popup>}
                {(this.props.embedMode ? true : this.props.privileges.includes(PrivilegeConstants.MULTI_TOUCH)) && <Popup content={APPS_IN_THIS_DEVICE_HEADER()}
                                                                position='right center'
                                                                trigger={
                                                                    <Button
                                                                            icon={UNORDERED_LIST_ICON}
                                                                            onClick={() => this.setState({appsInDeviceModalVisibility: true})}/>
                                                                }
                />}
                {!this.props.embedMode && <Popup content={multiManageOperationMessages().SHARE_SCREEN_POPUP}
                        position='right center'
                        trigger={
                            <Button
                                icon={SHARE_ALTERNATE_ICON}
                                onClick={() => this.handleEmbeddedScreen()}/>
                        }
                />}
                <Popup
                  content='Upload Simulation Resource'
                  position='right center'
                  trigger={
                      <Button icon='microchip'
                              onClick={() => this.setState({simulationResModalVisibility: true})}/>
                  }
                />
                {!this.props.embedMode && this.props.deviceReservationData?.length > 0 &&
                <Popup
                    flowing
                    on={['hover', 'click']}
                    open={this.state.showModalReservation ? false : undefined}
                    hoverable
                    position='right center'
                    trigger={
                        <Button
                            icon={CALENDAR_ALTERNATE_OUTLINE_ICON}
                        />
                    }
                >
                    <DeviceReservationInfo
                        deviceReservationData={this.props.deviceReservationData}
                        onShowReservationModal={this.onShowReservationModal}
                    />
                </Popup>}
                <br/>
                {/*RBA check*/}
                <Button circular color='red' icon={ADD_ICON} onClick={this.actionButtonClick} className={"attentionButton"}
                        style={this.state.isIconsExpanded ? {
                                transform: 'rotate(45deg) scale(1) translateY(0%) translateX(0%)'
                            }
                            : {
                                transform: 'rotate(0deg) scale(1) translateY(0%) translateX(0%)'
                            }}/>
                {this.props.privileges.includes(PrivilegeConstants.REBOOT_SHUTDOWN) && <Popup content={multiManageOperationMessages().REBOOT_POPUP}
                                                                position='right center'
                                                                trigger={
                                                                    <Button color='red' circular icon={SYNC_ICON}
                                                                            className={"actionButton"}
                                                                            style={this.state.isIconsExpanded ? {transform: "scale(1)"} : {transform: "scale(0)"}}
                                                                            onClick={() => this.setState({
                                                                                rebootOrShutdown: 'Reboot',
                                                                                openConfirm: true
                                                                            })}/>
                                                                }
                />}
                {/*RBA check*/}
                {this.props.privileges.includes(PrivilegeConstants.REBOOT_SHUTDOWN) && <Popup content={multiManageOperationMessages().SHUTDOWN_POPUP}
                                                                position='right center'
                                                                trigger={
                                                                    <Button circular color='red' icon={POWER_ICON}
                                                                            className={"actionButton"}
                                                                            style={this.state.isIconsExpanded ? {transform: "scale(1)"} : {transform: "scale(0)"}}
                                                                            onClick={() => this.setState({
                                                                                rebootOrShutdown: 'Shutdown',
                                                                                openConfirm: true
                                                                            })}/>}
                />}


                {(this.props.embedMode ? true : this.props.privileges.includes(PrivilegeConstants.MULTI_TOUCH)) && <Popup content={multiManageOperationMessages().LOCK_SCREEN_POPUP}
                                                                position='right center'
                                                                trigger={
                                                                    <Button color='red' circular icon={LOCK_ICON}
                                                                            className={"actionButton"}
                                                                            style={this.state.isIconsExpanded ? {transform: "scale(1)"} : {transform: "scale(0)"}}
                                                                            onClick={this.props.handleSwitchLock}
                                                                    />}
                />}


                {!this.props.embedMode && this.props.deviceOs?.toLowerCase() === 'android' && this.props.isVFAgentAvailable &&
                <Popup content={multiManageOperationMessages().CHANGE_DEVICE_LOCALE_POPUP}
                       position='right center'
                       trigger={
                           <Button circular color='red' icon={WORLD_ICON} className='actionButton'
                                   style={this.state.isIconsExpanded ? {transform: 'scale(1)'} : {transform: 'scale(0)'}}
                               onClick={() => this.setState({changeDeviceLocaleModalVisibility: true})}/>
                       }
                />}
                {/*<Button icon='time' content='Reservations' color="teal" onClick={() => {this.setState({reserveModalShow:true})*/}
                {/*}}/>*/}
                <Notification openPortal={this.state.openPortal}
                              headerPortal={this.state.headerPortal}
                              contentPortal={this.state.contentPortal}
                              actionsPortal={this.state.actionsPortal}/>
                <Confirm
                    open={this.state.openConfirm}
                    header={multiManageOperationMessages(null,null,this.state.rebootOrShutdown).REBOOT_SHUTDOWN_CONFIRM_HEADER}
                    onCancel={() => this.setState({openConfirm: false})}
                    onClose={() => this.setState({openConfirm: false})}
                    content={multiManageOperationMessages(null,null,this.state.rebootOrShutdown).REBOOT_SHUTDOWN_CONFIRM_MESSAGE}
                    cancelButton={CANCEL_BUTTON()}
                    confirmButton={YES_LABEL()}
                    onConfirm={() => {
                        this.onConfirmAction()
                    }}
                />
                {/* Video Player Modal*/}
                <Modal
                    basic
                    className='video-player' 
                    open={this.state.videoModalVisibility}
                    onClose={()=> {
                        this.setState({videoModalVisibility: false})
                    }}
                    closeOnEscape={true}
                    centered={true}
                    size='mini'>

                    <Modal.Content>
                        <Segment basic loading={this.state.videoLoading} padded>
                            <MediaViewer isVideo={true} isImage={false} src={this.state.videoSrc}/>
                        </Segment>
                    </Modal.Content>
                </Modal>

                {/* Device Locale Modal*/}
                <Modal className='change-device-locale' open={this.state.changeDeviceLocaleModalVisibility}
                       onClose={() => this.setState({changeDeviceLocaleModalVisibility: false})}>
                    <Modal.Header>{multiManageOperationMessages().CHANGE_DEVICE_LOCALE_POPUP}</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Group>
                                <Form.Input placeholder={multiManageOperationMessages().CHANGE_DEVICE_LOCALE_PLACEHOLDER}
                                            onChange={(event, data) => this.handleTargetDeviceLocaleInput(data)}/>
                                <Form.Button icon color='green' disabled={this.state.isChangeDeviceLocaleDisabled}
                                             onClick={() => this.setState({changeDeviceLocaleModalVisibility: false,
                                                 isChangeDeviceLocaleDisabled: true},
                                        this.props.handleChangeDeviceLocale(this.state.targetDeviceLocale))}>
                                    <Icon name={CHECKMARK_ICON}/>
                                </Form.Button>
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                </Modal>
                {/*App deploy modal*/}
                <Modal
                    size={"small"}
                    open={this.state.deploymentModalVisibility}
                    closeOnDimmerClick={!this.state.loadingDeployment}
                    closeIcon
                    onClose={() => this.setState({
                        deploymentModalVisibility: false,
                        selectedAppList: [],
                        selectedFile: [],
                        searchKeyword: '',
                        fileSearchInput: ''
                    })}
                >
                    <Modal.Header style={{textAlign: 'center'}}>{multiManageOperationMessages().APPLICATION_DEPLOYMENT_POPUP}</Modal.Header>
                    <div className='main-left-content'>
                        <Tab
                            panes={this.deploymentTabPanes()}
                            paneWidth={12}
                            tabWidth={4}
                            onTabChange={(e, {activeIndex})=>{
                                if(activeIndex === 0){
                                    this.fetchApplicationList();
                                    this.setState({selectedFile: [], fileSearchInput: ''})
                                }else{
                                    this.setState({searchKeyword: ''})
                                    this.fetchFileList();
                                }
                            }}
                        />
                    </div>
                    <Modal.Actions>
                        <Button
                            disabled={this.state.loadingDeployment}
                            icon
                            labelPosition={"left"}
                            color={"red"}
                            onClick={() => this.setState({
                                deploymentModalVisibility: false,
                                selectedAppList: [],
                                selectedFile: [],
                                searchKeyword: ''
                            })}>
                            <Icon name={X_ICON}/>
                            {CLOSE_BUTTON()}
                        </Button>
                    </Modal.Actions>
                </Modal>
                <AppsInDeviceModal
                    appsInDeviceModalVisibility={this.state.appsInDeviceModalVisibility}
                    deviceId={this.props.id}
                    embedMode={this.props.embedMode}
                    onClose={() => this.setState({appsInDeviceModalVisibility: false})}
                />
                {!this.props.embedMode && <SharedUrlModal
                    sharedUrlModalVisibility={this.state.sharedUrlModalVisibility}
                    onCloseSharingModal={() => this.setState({sharedUrlModalVisibility: false})}
                    sharedUrlView={this.state.sharedUrlView}
                    sharedUrlManage={this.state.sharedUrlManage}
                    sharedUrlInspector={this.state.sharedUrlInspector}
                    refreshToken={(type) => this.refreshToken(type)}
                    localeProp={this.props.i18n.language}
                    deviceId={this.props.id}
                />}
                {!this.props.embedMode && <ReservationModal
                    showModal={this.state.showModalReservation}
                    onCloseModal={() => this.setState({showModalReservation : false}, () => this.props.getReservationInfo())}
                    device={this.props.selectedDevice}
                    isInDeviceManage={true}
                    privileges={this.props.privileges}
                />}

                <SimulationResourceUpload
                    deviceUdId = {this.state.currentDeviceUdId}
                    visibility = {this.state.simulationResModalVisibility}
                    handleCloseModal = {() =>
                        this.setState({simulationResModalVisibility:false})
                    }
                />

            </div>
        );
    }
}

export default withTranslation()(withRouter(MultiManageOperation));
