import React, {Component} from 'react';
import {
    Button,
    Confirm,
    Dimmer,
    Dropdown,
    Grid,
    Header,
    Icon,
    Input,
    Label,
    Loader,
    Segment,
    Table,
    Modal,
    Popup
} from 'semantic-ui-react';
import packageJSON from '../../../../package.json';
import axios from 'axios';
import {Flip, toast} from 'react-toastify';
import {withTranslation} from 'react-i18next';
import _ from 'lodash'
import {Link, withRouter} from 'react-router-dom';

import {showError} from '../../../utils/ToastHelpers';
import {formatBytes, getPrivileges} from '../../../utils/Methods';
import {
    getApplicationVersion,
    getNodeList,
    exportDevicesInformation
} from '../../../api/apiCalls'
import { PrivilegeConstants } from '../../../utils/PrivilegeConstants';
import { API_DOCUMENTATION_HEADER, CANCEL_BUTTON, CLOSE_BUTTON, CONFIRM_BUTTON, DEVICE_ID_HEADER, 
    DEVICE_STATUS_HEADER, FAILED_ERROR_MESSAGE, FILE_SIZE_HEADER, HIDE_BUTTON, LOADING, otherOpsMessages, 
    OTHER_LABEL, SHOW_BUTTON, SUCCESS_MESSAGE, UPLOAD_BUTTON, EXPORT_DEVICE_INFORMATION
} from '../../../utils/UIMessages';
import { CHECKMARK_ICON, DONT_ICON, FILE_ALTERNATE_ICON, POWER_ICON, REDO_ICON, X_ICON, CSV_ICON } from '../../../utils/UiIcons';

class OtherOpsPage extends Component {
    state = {
        language: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
        uuid: '',
        licenseDetails: {},
        licenseFile: undefined,
        loadingForLicense: false,
        loadingForDownloadLog: false,
        frontVersion: packageJSON.version,
        backVersion: '',
        userDetails: {},
        privileges: [],
        rebootOrShutdown: '',
        confirmOpen: false,
        deviceStatusModelOpen: false,
        deviceStatusList: [],
        licenseSignature: '',
        licenseSignatureShorted: '',
        showOrHide: true,
        clients: [],
        nodes:[],
        fileSize: null,
        loadString: '',
        loadingForDeviceStatus: false
    };

    getLicenseDetails = () => {
        this.setState({loadingForLicense: true})
        axios.get('/api/license').then(res => {
            let licenseResponse = res.data
            if (!licenseResponse.includes('Trial')) {
                licenseResponse = licenseResponse
                    .replace(/:INT/g, '')
                    .replace(/:DATE/g, '')
                    .replace(/:DATE/g, '')
                    .replace(/:BINARY/g, '')
                    .replace(/:UUID/g, '')
                    .split(/\n|=/)
                    .filter((value => value !== ''))
                let licenseDetails = []
                for (let i = 0; i < licenseResponse.length; i += 2) {
                    const camelCaseToNormal = _.startCase(licenseResponse[i])
                    licenseDetails.push([camelCaseToNormal, licenseResponse[i + 1]]);
                }
                licenseDetails = Object.fromEntries(licenseDetails)
                const licenseSignature = licenseDetails['License Signature']
                const licenseSignatureShorted = licenseDetails['License Signature'].slice(0, 50) + '...'
                this.setState({licenseDetails, licenseSignature, licenseSignatureShorted, loadingForLicense: false})
            } else {
                licenseResponse = licenseResponse
                    .replace(/:INT/g, '')
                    .replace(/:DATE/g, '')
                    .split(/\n|=/)
                    .filter((value => value !== ''))
                let licenseDetails = []
                for (let i = 0; i < licenseResponse.length; i += 2) {
                    const camelCaseToNormal = _.startCase(licenseResponse[i])
                    licenseDetails.push([camelCaseToNormal, licenseResponse[i + 1]]);
                }
                licenseDetails = Object.fromEntries(licenseDetails)
                this.setState({licenseDetails, loadingForLicense: false})
            }
        }).catch(err => {
            showError(otherOpsMessages().NOT_CREATE_OR_FETCH_LICENSE_INFO_ERROR_MESSAGE + err?.response?.data?.message ?? err.toString())
            this.setState({loadingForLicense: false})
        })
    }

    onChangeLicenseInput = e => {
        const file = e.target.files[0];
        const licenseFile = new FormData();
        licenseFile.append('file', file, file.name);
        this.setState({licenseFile});
    }

    onSubmitLicense = () => {
        this.setState({loadingForLicense: true})
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        axios.post('/api/license', this.state.licenseFile, config).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                delay: 1000,
                transition: Flip
            });
            this.getLicenseDetails()
            this.setState({loadingForLicense: false})
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString());
            this.setState({loadingForLicense: false})
        })
    }

    handleConfirm = () => {
        this.setState({loadingForDeviceStatus: true, deviceStatusModelOpen: true, confirmOpen: false})
        if (this.state.rebootOrShutdown === 'Reboot') {
            axios.get('/api/screen-share/reboot-all').then(res => {
                const deviceStatusList = Object.keys(res?.data).map(key => {
                    const status = res?.data[key]
                    return {
                        key,
                        text: status
                    };
                });
                this.setState({deviceStatusList, loadingForDeviceStatus: false});
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString());
                this.setState({loadingForDeviceStatus: false});
            })
        } else if (this.state.rebootOrShutdown === 'Shutdown') {
            axios.get('/api/screen-share/shutdown-all').then(res => {
                const deviceStatusList = Object.keys(res?.data).map(key => {
                    const status = res?.data[key]
                    return {
                        key,
                        text: status
                    };
                });
                this.setState({deviceStatusList, loadingForDeviceStatus: false});
            }).catch(err => {
                showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString());
                this.setState({loadingForDeviceStatus: false});
            })

        }
    };
    renderStatusIcon = deviceStatus => {
        if (deviceStatus === 'SUCCESS') {
            return <Icon name={CHECKMARK_ICON} color='green'/>
        } else if (deviceStatus === 'NOT_EXECUTED') {
            return <Icon name={DONT_ICON} color='gray'/>
        } else {
            return <Icon name={X_ICON} color='red'/>
        }
    }

    onClickShowSignature = () => {
        if (this.props.t(this.state.showOrHide) === SHOW_BUTTON()) {
            this.setState({showOrHide: HIDE_BUTTON()})
        }
        if (this.props.t(this.state.showOrHide) === HIDE_BUTTON()) {
            this.setState({showOrHide: SHOW_BUTTON()})
        }
    }

    getAllNodes = async() => {
        try{
            const response = await getNodeList();
            let nodes = response?.data?.map(node=>{
                const text = node?.ip === node?.name ? node.name :`${node?.name} (${node?.ip})`
                return({
                    key:node?.id,
                    value:node?.id,
                    text
                })
            })
            nodes = [...nodes, 
                {key: 'all', value: 'all', text: otherOpsMessages().ALL_DATA_LABEL},
                {key:'ui', value:'ui', text:otherOpsMessages().UI_LOGS_LABEL},
                {key:'grid', value:'grid', text:otherOpsMessages().GRID_HUB_DIAGNOSTICS_LABEL}
            ]
            this.setState({nodes})
        }catch(err){
            showError(`${otherOpsMessages().NOT_FETCH_NODES}: ${err?.response?.message ?? err.toString()}`);
        }
    }


    onLogClick = (e, {value,text}) => {
        let loadString, fileName;
        let id = value; 
        if (["all", "ui", "grid"].includes(value)) {
            [loadString, fileName] = [`${otherOpsMessages().ALL_LOGS_LABEL}`, `${value}_system_data.zip`]
        } else {
            [loadString, fileName] = [`${text} ${otherOpsMessages().LOGS_LABEL}`, `${text}_system_data.zip`]
        }
        this.setState({loadString, loadingForDownloadLog: true})
        axios.get(`/api/v1/system/diagnosis/${id}`, {
            responseType: 'arraybuffer',
            onDownloadProgress: progressEvent => {
                const {loaded} = progressEvent;
                this.setState({fileSize: formatBytes(loaded)})
            }
        }).then(res => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([res.data], {type: 'application/zip'}));
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(err => {
            showError(`${otherOpsMessages().NOT_FETCH_LOGS}: ${err?.response?.message ?? err.toString()}`)
        }).finally(() => {
            this.setState({loadingForDownloadLog: false, loadString: '', fileSize: null})
        })
    }

    downloadDeviceInfoToCSV = () => {
        exportDevicesInformation().then(res=> {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'devices.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
          }).catch(e => showError(`Error while downloading device csv file ${e}`));
    }

    componentDidMount() {
        const privileges = getPrivileges();
        this.setState({privileges}, () => {
            getApplicationVersion().then(res => {
                this.setState({backVersion: res.data.version})
            })
            if (this.state.privileges.includes(PrivilegeConstants.LICENSE_MANAGEMENT)) {
                axios.get('/api/license/uuid').then(res => {
                    this.setState({uuid: res.data})
                }).catch(err => {
                    showError(otherOpsMessages().NOT_FETCH_DEVICE_UUID_ERROR_MESSAGE + err?.response?.data?.message ?? err.toString())
                })
                this.getLicenseDetails();
                if(this.state.privileges.includes(PrivilegeConstants.DIAGNOSIS)){
                    this.getAllNodes();
                }
            }
        })
    }

    closeDeviceStatusModel = () => {
        this.setState({deviceStatusModelOpen: false, confirmOpen: false});
    }


    render() {
        return (
            <div>

                <div className="main-right-header">
                    <div>
                        <h2> {otherOpsMessages().OTHER_SETTINGS_HEADER}</h2>
                        <small>{otherOpsMessages().OTHER_SETTINGS_SUB_HEADER}</small>
                    </div>
                </div>


                <Grid>
                    <Grid.Row className='shadow' style={{backgroundColor: '#ffffff', marginTop: 14}} columns={1}>
                        <Grid.Column
                            // style={{marginTop: 30}}
                        >
                            <Dimmer active={this.state.loadingForDownloadLog} page>
                                <Loader active={this.state.loadingForDownloadLog}
                                        indeterminate>{LOADING()} {this.state.loadString} <br/> <br/> {FILE_SIZE_HEADER()}: {this.state.fileSize || 0}</Loader>
                            </Dimmer>
                            {this.state.privileges.includes(PrivilegeConstants.LICENSE_MANAGEMENT) &&
                            <Segment basic loading={this.state.loadingForLicense} style={{margin: 0, padding: 0}}>
                                <>
                                    <Header as='h3' block>
                                        {otherOpsMessages().LICENSE_INFORMATION_HEADER}
                                    </Header>

                                    <Table celled singleLine id='settings-other-info-table' className='license-table'>
                                        <Table.Body className="table-licenseInformation">
                                            <Table.Row>
                                                <Table.Cell className="licenseInfoTable-leftColumn">{otherOpsMessages().DEVICE_UUID_CELL}</Table.Cell>
                                                <Table.Cell>{this.state.uuid}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell className="licenseInfoTable-leftColumn">{otherOpsMessages().LICENSE_DETAIL_CELL}</Table.Cell>
                                                <Table.Cell className="table-licenseDetail">
                                                    <Table basic={'very'} celled className="licenseDetails-table">
                                                        <Table.Body>
                                                            {Object.keys(this.state.licenseDetails).map((detail, i) => {
                                                                const wordStying = {'wordBreak': 'break-all', 'white-space': 'pre-wrap'}
                                                                return <Table.Row key={i}>
                                                                    <Table.Cell>
                                                                        {this.props.t(detail)}
                                                                    </Table.Cell>
                                                                    <Table.Cell style={wordStying}>
                                                                        {this.props.t(detail) === otherOpsMessages().LICENSE_SIGNATURE_LABEL ? <>
                                                                            <span className='license-signature'>
                                                                                {this.state.showOrHide ?
                                                                                    this.state.licenseSignatureShorted : this.state.licenseSignature}
                                                                            </span>
                                                                            <Label
                                                                                style={wordStying}
                                                                                basic as={'a'}
                                                                                onClick={() => {
                                                                                    this.setState({ showOrHide: !this.state.showOrHide })
                                                                                }}>
                                                                                {this.state.showOrHide ? SHOW_BUTTON() : HIDE_BUTTON()}
                                                                            </Label></> : this.state.licenseDetails[detail]}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>


                                    <Header size={'small'}>{otherOpsMessages().NEW_LICENSE_HEADER}</Header>
                                    <div style={{display: 'flex'}}>
                                        <Input
                                            size='small'
                                            type='file'
                                            accept='.lic'
                                            onChange={this.onChangeLicenseInput}
                                            style={{width: 'min-content', 'margin-right': '10px', 'min-width': 0}}
                                        />
                                        <Button onClick={this.onSubmitLicense} disabled={!this.state.licenseFile}>
                                            {UPLOAD_BUTTON()}
                                        </Button>
                                    </div>
                                </>

                            </Segment>}
                        </Grid.Column>

                        <Grid.Column style={{marginTop: 30}} className='main-opts'>
                            <div>
                                <>
                                    <Header as='h3' block>
                                        {OTHER_LABEL()}
                                    </Header>
                                    <Grid columns={4} id='other-opts-button-group'>
                                        {/*RBA check*/}
                                        {this.state.privileges.includes(PrivilegeConstants.SHUTDOWN_ALL) &&
                                        <>
                                            <Grid.Column className='other-opts-button'>
                                                <Button fluid compact color={'red'} onClick={() => {
                                                    this.setState({
                                                        rebootOrShutdown: 'Shutdown',
                                                        confirmOpen: true
                                                    })
                                                }}><Icon name={POWER_ICON}/>{otherOpsMessages().SHUTDOWN_ALL_DEVICES_BUTTON}</Button>
                                            </Grid.Column>
                                            <Grid.Column className='other-opts-button'>
                                                <Button fluid compact color={'yellow'}
                                                        onClick={() => {
                                                            this.setState({
                                                                rebootOrShutdown: 'Reboot',
                                                                confirmOpen: true
                                                            })
                                                        }}><Icon name={REDO_ICON}/>{otherOpsMessages().RESTART_ALL_DEVICES_BUTTON}
                                                </Button>
                                            </Grid.Column>
                                        </>
                                        }

                                        {this.state.privileges.includes(PrivilegeConstants.DIAGNOSIS) &&
                                        <>
                                            <Grid.Column className='other-opts-button'>
                                                <Dropdown
                                                    text={otherOpsMessages().COLLECT_SYSTEM_DATA_DROPDOWN_TEXT}
                                                    fluid
                                                    button
                                                    compact
                                                    id='collect-system-data-button'
                                                    scrolling
                                                >
                                                    <Dropdown.Menu>
                                                        {this.state?.nodes.map(node => <Dropdown.Item
                                                            key={node?.key} text={node?.text}
                                                            onClick={this.onLogClick} value={node?.value}/>)}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Grid.Column>
                                        </>
                                        }
                                        <Grid.Column className='other-opts-button'>
                                            <Button fluid compact color={'orange'}
                                                    as={Link}
                                                    to={'/api-documentation'}
                                            ><Icon
                                                name={FILE_ALTERNATE_ICON}/>{API_DOCUMENTATION_HEADER()}
                                            </Button>
                                        </Grid.Column>
                                    {
                                        ([
                                            PrivilegeConstants.DEVICE_LIST,
                                            PrivilegeConstants.ACCESS_AUTOMATION
                                        ].some(privilege => this.state.privileges.includes(privilege))) &&
                                        <Grid.Column className='other-opts-button'>
                                            <Button fluid compact color={'green'}
                                                onClick={()=>this.downloadDeviceInfoToCSV()}
                                            ><Icon
                                                name={CSV_ICON}/>{EXPORT_DEVICE_INFORMATION()}
                                            </Button>
                                        </Grid.Column>
                                    }
                                    </Grid>
                                </>

                            </div>
                        </Grid.Column>
                        <Confirm
                            open={this.state.confirmOpen}
                            header={otherOpsMessages(this.state.rebootOrShutdown).SHUTDOWN_OR_RESTART_ALL_DEVICES_CONFIRM_HEADER}
                            content={otherOpsMessages(this.state.rebootOrShutdown).SHUTDOWN_OR_RESTART_ALL_DEVICES_CONFIRM_MESSAGE}
                            onCancel={() => {
                                this.setState({ confirmOpen: false })
                            }}
                            onConfirm={this.handleConfirm}
                            confirmButton={CONFIRM_BUTTON()}
                            cancelButton={CANCEL_BUTTON()}
                        />
                        <Modal open={this.state.deviceStatusModelOpen} size='small' closeIcon onClose={this.closeDeviceStatusModel}>
                            <Modal.Header>{otherOpsMessages().RESTART_DEVICE_STATUS_MODAL_HEADER}</Modal.Header>
                            <Modal.Content>
                                {this.state.loadingForDeviceStatus ?
                                    <Segment style={{height: '50vh'}}>
                                        <Dimmer active inverted>
                                            <Loader>{otherOpsMessages().PLEASE_WAIT_LOADER}</Loader>
                                        </Dimmer>
                                    </Segment> :
                                    <Table basic='very' celled compact>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>{DEVICE_ID_HEADER()}</Table.HeaderCell>
                                                <Table.HeaderCell>{DEVICE_STATUS_HEADER()}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.state?.deviceStatusList?.map((deviceStatus, i) =>
                                                <Table.Row key={i}>
                                                    <Table.Cell>
                                                        {deviceStatus.key}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Popup trigger={this.renderStatusIcon(deviceStatus.text)}
                                                               content={deviceStatus.text}
                                                               basic
                                                               position={'bottom center'}
                                                               on='hover'
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>
                                }
                            </Modal.Content>
                            <Modal.Actions>
                                <Button.Group>
                                    <Button icon labelPosition='right' color='red'
                                            onClick={this.closeDeviceStatusModel}>
                                        <Icon name={X_ICON}/> {CLOSE_BUTTON()}</Button>
                                </Button.Group>
                            </Modal.Actions>
                        </Modal>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(withRouter(OtherOpsPage));
