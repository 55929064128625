import React from 'react'
import { Button, Grid, Icon, Popup } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import IdleModal from '../../components/TimedOutModal';
import WarningModal from '../../components/TimedOutWarningModal';
import ErrorModal from '../../components/ErrorModal';
import { DEBOUNCE_WAIT, POPUP_POSITIONS} from '../../utils/Constants';
import qs from 'query-string';
import Bowser from 'bowser';

import DeviceInstance from './DeviceInstance';
import { showError } from '../../utils/ToastHelpers';
import AddDeviceModal from './AddDeviceModal';
import _ from 'lodash';
import UsedDeviceWarningModal from '../device/UsedDeviceWarningModal';
import {Slide, toast} from 'react-toastify';
import {getPrivileges, parseJwt, secondTohhmmss, timeoutShowCondition} from '../../utils/Methods';
import {
  getSystemParameters,
  stopTestSession,
  getVisiumManageIntegration
} from '../../api/apiCalls';
import { CONCURRENT_ACCESS_LIMIT_LABEL, multiManageMessages, integrationMessages } from '../../utils/UIMessages';
import { BACKWARD_ICON, EXCLAMATION_ICON, STOP_ICON } from '../../utils/UiIcons';
import Chat from '../../components/chat/Chat';
import TestRunCompletionModal from './TestRunCompletionModal';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';

class MultiManagePage extends React.Component {
  deviceInstanceRefs = {};
  timeoutToastId = React.createRef()
  tabIdleInterval = null;
  // browser tab'ının focus dışı süresini takip etmek için
  afkIdleInterval = null;
  // sayfanın tamamıyla etkileşim olmayan süreyi takip etmek için
  state = {
    privileges: [],
    isFirefox: false,
    connectedDeviceIdList: [],
    connectedClientList: [],        // bağlı cihazların AndroidClient'ları (websocket'leri bu component'ten kapatabilmek için)
    detailedInfoId: null,           // sağ tarafta detaylı bilgi bölümü açık olan cihazın ID'si
    warningModalVisibility: false,
    warned: false,
    idleModalVisibility: false,
    tabOrIdle: 'idle',
    fromServerIdle: null,           // bu ikisi fixed, serverdan gelen değerleri tutmak için
    fromServerTabIdle: null,        // ^
    idleTimer: null,                // bu ikisi değişken, idle geçen süreyi takip etmek için
    tabIdleTimer: null,             // ^
    errorModalVisibility: false,
    errorModalHeader: "",
    errorModalContent: "",
    errorButtonContent: "",
    sessionId: "",
    refresh: false,
    busyStateList: [],
    isOpenUsedDeviceWarningModal: false,
    embedMode:'',
    chatAppAvailability:false,
    visibilityOfBusyDevice:'yes',
    chatPortalVisibility : false,
    chatDeviceList:[],
    testRunCompletionModalState: false,
    visiumManageActive: null
  };

  notifyTimeoutToast = timeInSeconds => {
    this.timeoutToastId.current =
        toast(<>{`${multiManageMessages().DEVICE_TIMEOUT_REMAINING_TIME_NOTIFY_MESSAGE}`}: <strong> {secondTohhmmss(timeInSeconds)} </strong></>,
        {
          position: 'bottom-right',
          className: 'timeout-toast',
          autoClose: false,
          closeOnClick: false,
          closeButton: false,
          transition: Slide,
          draggable: false
        }
    )
  }

  dismissTimeoutToast = () => toast.dismiss(this.timeoutToastId.current)

  updateTimeoutToast = timeInSeconds => toast.update(this.timeoutToastId.current,
      {
        render: <>{`${multiManageMessages().DEVICE_TIMEOUT_REMAINING_TIME_NOTIFY_MESSAGE}`}: <strong> {secondTohhmmss(timeInSeconds)} </strong></>
      }
  )

  notifyOrUpdateTimeoutToast = () => {
    if (this.state.idleTimer === timeoutShowCondition(this.state.fromServerIdle)){
      this.notifyTimeoutToast(this.state.idleTimer)
    }
    if (this.state.idleTimer <= timeoutShowCondition(this.state.fromServerIdle)){
      this.updateTimeoutToast(this.state.idleTimer)
    }
  }

  getBusyStateFromStatus = status => {
    return Object.keys(status).reduce((result, k) => {
      if (status[k]) {
        result.push(k);
      }
      return result;
    }, []);
  };

  addToBusyStateList = (id, status) => {
    this.setState(prev => ({
      busyStateList: prev.busyStateList.concat({
        id,
        busyState: this.getBusyStateFromStatus(status)
      })
    }));
  };

  closeUsedDeviceWarningModal = () => {
    this.setState({
      isOpenUsedDeviceWarningModal: false,
      busyStateList: []
    });
  };

  openUsedDeviceWarningModal = () => {
    if(this.state.visibilityOfBusyDevice==='yes'){
      this.setState({ isOpenUsedDeviceWarningModal: true });
    }
  };

  addToClientList = (client) => {
    this.setState(prev => ({
      connectedClientList: prev.connectedClientList.concat(client)
    }));
  }

  onCloseWarningModal = () => {
    this.handleOnCloseWarning();
    this.setState({ warningModalVisibility: false });
  }

  onCloseIdleModal = () => {
    this.setState({ idleModalVisibility: false });
  }

  setDetailedInfoId = (id) => {
    this.setState({ detailedInfoId: id });
  }

  onReconnect = () => {
    this.initialize();
    this.setState({
      idleModalVisibility: false,
      errorModalVisibility: false,
      refresh: !this.state.refresh
    });
  }

  handleOnCloseWarning = () => {
    for (const child in this.deviceInstanceRefs) {
      this.deviceInstanceRefs[child]?.handleOnCloseWarning();
    }
  }

  handleOnFocus = () => {
    for (const child in this.deviceInstanceRefs) {
      this.deviceInstanceRefs[child]?.handleHasFocus();
    }
  }

  resetCounter = () => {
    for (const child in this.deviceInstanceRefs) {
      this.deviceInstanceRefs[child]?.handleCursorMove();
    }
  }

  onMoveCursor = _.debounce(this.resetCounter, DEBOUNCE_WAIT.SHORT);

  onAddEventListeners = () => {
    window.addEventListener('mousedown', this.resetCounter);
    window.addEventListener('dragend', this.resetCounter);
    window.addEventListener('mousemove', this.onMoveCursor);
    window.addEventListener("blur", this.handleOnFocus);
    window.addEventListener("focus", this.handleOnFocus);
  }

  getSystemParameters = async () => {
    if (this.state.embedMode) {
      this.setState({
        idleTimer: 600,
        tabIdleTimer: 300,
        fromServerIdle: 600,
        fromServerTabIdle: 300
      }, this.onAddEventListeners);
    } else {
      getSystemParameters().then(res => {
        let idleTimer = res.data.find(param => param.parameterKey === 'deviceInteractionScreenTimeout')?.parameterValue;
        let tabIdleTimer = res.data.find(param => param.parameterKey === 'activeWindowScreenTimeout')?.parameterValue;
        const chatAppAvailability = res.data.find(param => param.parameterKey === 'isChatAppEnabled')?.parameterValue;
        const visibilityOfBusyDevice = res.data.find(param => param.parameterKey === 'visibilityOfBusyDevice')?.parameterValue;
  
        this.setState({chatAppAvailability, visibilityOfBusyDevice})
        idleTimer = +idleTimer
        tabIdleTimer = +tabIdleTimer
        this.setState({
          idleTimer,
          tabIdleTimer,
          fromServerIdle: idleTimer,
          fromServerTabIdle: tabIdleTimer
        }, this.onAddEventListeners);
      }).catch(err => {
        console.error("Error while getting system parameters. Falling back to default values.", err)
        this.setState({
          idleTimer: 600,
          tabIdleTimer: 300,
          fromServerIdle: 600,
          fromServerTabIdle: 300
        }, this.onAddEventListeners);
      })
    }
  }

  setErrorInfo = (errVisibility, errHeader, errContent, errBtnContent) => {
    this.setState({
      errorModalVisibility: errVisibility,
      errorModalHeader: errHeader,
      errorModalContent: errContent,
      errorButtonContent: errBtnContent
    });
  }

  onClickErrorModalConfirm = () => {
    this.props.history.push("/device-list");
  }

  onCloseErrorModalConfirm = () => {
    this.setState({
      errorModalVisibility: false,
    });
  }

  onOpenTestRunModal = () => {
    this.setState({testRunCompletionModalState: true});
  }

  onCloseTestRunModal = () => {
    this.setState({testRunCompletionModalState: false});
  }

  onClickEndSession = () => {
    if(this.state.visiumManageActive === true){
      this.onOpenTestRunModal();
    }else{
      stopTestSession(this.state.sessionId).then((res) => {
        this.props.history.push(`/manual-test-sessions?taskId=${res.data.taskId}`)

      }).catch(err => {
        showError((`${multiManageMessages().NOT_STOPPED_TEST_SESSION}: ${err.message}`))
      })
    }
  }

  addToConnectedDeviceIdList = (newIdList) => {
    this.setState(prev => ({
      ...prev,
      connectedDeviceIdList: prev.connectedDeviceIdList.concat(newIdList),
      detailedInfoId: null
    }), () => {
      let queryStr = "?";
      this.state.connectedDeviceIdList.forEach((deviceId, idx) => queryStr += `device_${idx + 1}=${deviceId}&`);
      this.props.history.replace({ search: queryStr });
    });
  }

  removeFromConnectedDeviceIdList = (deviceId, goToDeviceList = true) => {
    this.setState(prev => ({
      ...prev,
      connectedDeviceIdList: prev.connectedDeviceIdList.filter(id => id !== deviceId),
      detailedInfoId: null
    }), () => {
      if (this.state.connectedDeviceIdList.length > 0) {
        let queryStr = "?";
        this.state.connectedDeviceIdList.forEach((deviceId, idx) => queryStr += `device_${idx + 1}=${deviceId}&`);
        this.props.history.replace({ search: queryStr });
      } else {
        if (goToDeviceList) {
          this.props.history.push("/device-list");
        }
      }
    });
  }

  addToChatDeviceList = (id,udId) =>{
    this.setState((prevState)=>({
      ...prevState, 
      chatDeviceList : [...this.state.chatDeviceList,{id,udId}]
    }))
  }

  warnForIdleDevice = (warningModalMessage) => {
    this.setState({warningModalVisibility: true, warningModalMessage});
  }

  termForIdleDevice = (idleModalMessage) => {
    this.setState({warningModalVisibility: false, idleModalVisibility: true, idleModalMessage});
  }

  initialize = () => {
    this.getSystemParameters();

    const browser = Bowser.getParser(window.navigator.userAgent);
    const isFirefox = browser.getBrowserName().toLowerCase().includes('firefox');
    if (!this.state.embedMode){
      // const privileges = Base64.decode(localStorage.getItem('privileges')).split(',');
      const privileges = getPrivileges();
      this.setState({privileges})
    }
    this.setState({ isFirefox });

   if (!this.state.embedMode){
     // URL'den device ID'lerini al
     const query = qs.parse(this.props.location.search);
     const { device_1, device_2, device_3, sessionId } = query;

     if (!device_1) {
       showError(multiManageMessages().DEVICE_ID_REQUIRED_ERROR_MESSAGE);
       this.props.history.push('/device-list');
     }
     const deviceIdList = [device_1];

     if (sessionId) {
       this.setState({ sessionId });
     } else {
       if (device_2) {
         deviceIdList.push(device_2);
       }
       if (device_3) {
         deviceIdList.push(device_3);
       }
     }

     this.setState({ connectedDeviceIdList: deviceIdList }, () => {
       if (this.state.connectedDeviceIdList.length === 1) {
         this.setState({ detailedInfoId: this.state.connectedDeviceIdList[0] });
       }
     });
   } else {
         const token = this.props.match.params;
         const tokenPayload = parseJwt(token);
         const deviceIdList = [tokenPayload.udId]

         if(this.state.embedMode==='inspector'){
             this.setState({ connectedDeviceIdList: deviceIdList }, () => {
                  this.setState({ detailedInfoId: this.state.connectedDeviceIdList[0] });
             });
         }else{
          this.setState({ connectedDeviceIdList: deviceIdList }, () => {
            this.setState({ detailedInfoId: null });
        });
         }
    }
  }

  async componentDidMount() {
    if (this.props.match.path.includes('/embed-manage/')) {
      this.setState({embedMode: 'manage'}, () => this.initialize())
    } else if (this.props.match.path.includes('/embed-inspector/')) {
      this.setState({embedMode: 'inspector'}, () => this.initialize())
    } else if(this.props.match.path.includes('/embed-view/')){
      this.setState({embedMode: 'view'}, () => this.initialize())
    } else {
      this.initialize()
    }
    if (this.state.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT) ||
      this.state.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT)) {
      getVisiumManageIntegration()
        .then(res => {
          this.setState({
            visiumManageActive: res?.data[0]?.active
          });
        })
        .catch(err => {
          showError(`${(integrationMessages().COULD_NOT_FETCH_MANAGE_INFO)} ${err?.response?.data?.message ?? err.toString()} `);
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMoveCursor);
    window.removeEventListener('mousedown', this.resetCounter);
    window.removeEventListener('dragend', this.resetCounter);
    window.removeEventListener("blur", this.handleOnFocus);
    window.removeEventListener("focus", this.handleOnFocus);
    this.setState({testRunCompletionModalState: false});
  }

  createRef = (id) => {
    const ref = React.createRef();
    this.deviceInstanceRefs[id] = ref;
    return ref;
  }

  render() {
    const queryParams = new URLSearchParams(window.location.search);
    const testId = queryParams.get('testId');
    const projectId = queryParams.get('projectId');
    const executionStartTime = queryParams.get('executionStartTime');

    return (
      <>

        {!this.state.embedMode && <div className="device-manage-page-actions">
          <>
            {this.state.sessionId !== "" ?
                <Button
                    id="endSession"
                    icon  color="red"
                    circular
                    onClick={this.onClickEndSession}>
                  <Icon name={STOP_ICON} />
                  {multiManageMessages().END_SESSION}
                </Button> :
                <>
                  <div className='device-actions'>
                  <AddDeviceModal
                      availableSpots={3 - this.state.connectedDeviceIdList.length}
                      addToConnectedDeviceIdList={this.addToConnectedDeviceIdList}
                      connectedDeviceIdList={this.state.connectedDeviceIdList}
                    embedMode={this.state.embedMode}
                  />

                  <Popup
                    position={POPUP_POSITIONS.TOP_RIGHT}
                    content={multiManageMessages().BACK_TO_DEVICE_LIST_POPUP}
                    trigger={
                      <Button circular icon style={{ marginTop: 6 }} color={'teal'}
                        onClick={() => {
                          window.localStorage.setItem(
                            'pathSaved',
                            'deviceList'
                          );
                          this.props.history.push('/device-list');
                        }}
                      >
                        <Icon name={BACKWARD_ICON} />
                      </Button>
                    }
                  />
                </div>
              </>
            }
            {(this.state.visiumManageActive && testId) &&
              <TestRunCompletionModal
                closeOnDimmerClick={false}
                open={this.state.testRunCompletionModalState}
                onOpen={this.onOpenTestRunModal}
                onClose={this.onCloseTestRunModal}
                detailedInfoId={this.state.detailedInfoId}
                sessionId={this.state.sessionId}
                testRunId={testId}
                projectId={projectId}
                executionStartTime={executionStartTime}
              />
            }
          </>
        </div>}
        {/* sağ tarafta detaylı bilgi kısmı açık cihaz varsa 2 column, diğer türlü cihaz sayısı kadar column */}
        <Grid stackable columns={this.state.detailedInfoId !== null ? 2 : this.state.connectedDeviceIdList.length}>
          <Grid.Row style={{ marginTop: 70 , marginLeft:30}}>

            {
              this.state.connectedDeviceIdList.map(id => (
                <DeviceInstance
                  onRef={ref => (this.deviceInstanceRefs[id] = ref)}
                  refresh={this.state.refresh}
                  id={id}
                  key={id}
                  privileges={this.state.privileges}
                  isFirefox={this.state.isFirefox}
                  detailedInfoId={this.state.detailedInfoId}
                  setDetailedInfoId={this.setDetailedInfoId}
                  addToClientList={this.addToClientList}
                  warnForIdleDevice={this.warnForIdleDevice}
                  termForIdleDevice={this.termForIdleDevice}
                  errorModalVisibility={this.state.errorModalVisibility}
                  setErrorInfo={this.setErrorInfo}
                  sessionId={this.state.sessionId}
                  onClickEndSession={this.onClickEndSession}
                  removeFromConnectedDeviceIdList={this.removeFromConnectedDeviceIdList}
                  connectedDeviceCount={this.state.connectedDeviceIdList.length}
                  addToBusyStateList={this.addToBusyStateList}
                  openUsedDeviceWarningModal={this.openUsedDeviceWarningModal}
                  embedMode={this.state.embedMode}
                  chatAppAvailability={this.state.chatAppAvailability}
                  addToChatDeviceList = {this.addToChatDeviceList}
                  chatPortalVisibility = {this.state.chatPortalVisibility}
                  setchatPortalVisibility={value=>this.setState({chatPortalVisibility:value})}
                />
              ))
            }
          </Grid.Row>
        </Grid>

        <IdleModal idleModalVisibility={this.state.idleModalVisibility} redirectPage={"/device-list"} message={this.state.idleModalMessage}
          onCloseIdleModal={this.onCloseIdleModal}
          onClickReconnect={this.onReconnect.bind(this)}
          isEmbedMode={this.state.embedMode !== ''} />

        <WarningModal warningModalVisibility={this.state.warningModalVisibility} message={this.state.warningModalMessage}
          onCloseWarningModal={this.onCloseWarningModal} />

        <ErrorModal closeOnDimmerClick={false}
          closeOnEscape={false}
          headerIcon={EXCLAMATION_ICON}
          visibility={this.state.errorModalVisibility}
          header={this.state.errorModalHeader}
          content={this.state.errorModalContent}
          buttonContent={this.state.errorButtonContent}
          onClick={this.state.errorModalHeader === CONCURRENT_ACCESS_LIMIT_LABEL() ? this.onCloseErrorModalConfirm : this.onClickErrorModalConfirm}
          onClickReconnect={this.onReconnect.bind(this)}
        />

        <UsedDeviceWarningModal
          open={this.state.isOpenUsedDeviceWarningModal}
          closeModal={this.closeUsedDeviceWarningModal}
          busyStateList={this.state.busyStateList}
        />

        { (this.state.connectedDeviceIdList.length > 0 && this.state.chatAppAvailability ==='true') &&
          <Chat
            type={'device'}
            chatPortalVisibility = {this.state.chatPortalVisibility}
            deviceList = {this.state.chatDeviceList} 
            setchatPortalVisibility = {value=>this.setState({chatPortalVisibility:value})} 
          />} 

      </>
    );
  }
}

export default withTranslation()(withRouter(MultiManagePage));
